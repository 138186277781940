import styled, { keyframes } from 'styled-components';
import InputMask from 'react-input-mask';

export const GlobalContainer = styled.div`
    width: 100%;
    height: auto;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
    background: linear-gradient(to right, #134e4a, #042f2e); 
`;

export const ContainerCenter = styled.div`

    width: 95%;
    height:auto;
    display: ${props=> (props.visible ? 'flex' : 'none')};
    justify-content: center;
    align-items:center;



    margin-right: ${props=>props.mr || '0px'};
    margin-top: ${props=>props.mt || '0px'};
    margin-bottom: ${props=>props.mb || '0px'};
    margin-left: ${props=>props.ml || '2.5%'};
    opacity: ${props => (props.visible ? '1' : '0')};

    transition: opacity 1.5s ease-in-out;
`

export const ContainerStart = styled.div`
    width: 1200px;
    display:flex;
    justify-content: start;
    align-items:center;    
    margin-top: ${props=>props.mt || '0px'};
`

export const ContainerEnd = styled.div`
    width: 1200px;
    display:flex;
    justify-content: end;
    align-items:center;    
`

export const Container = styled.div`

    width: auto;
    height: auto;
    background: linear-gradient(to right, ${props=>props.lgl || '#047C54'}, ${props=>props.lgr || '#00B774'}); 
    padding: 10px;
    border-radius: 10px;
    margin-top: ${props=>props.mt || '0px'};
    display:flex;
    justify-content: center;
    align-items:center;   
    
`

export const InputScheduling = styled.input`
    width: 100%;
    height: 45px;
    border-radius: 10px;
    margin-top: ${props => props.mt || '0px'};
    padding-left: 16px;
    font-size: 15px;
    border: none;

    &:focus {
        outline: none; /* Remove a borda ao clicar */
    }
`;

export const ButtonScheduling = styled.button`

    width: 100%;
    height: 45px;
    border-radius: 10px;
    margin-top: ${props => props.mt || '0px'};
    background: linear-gradient(to right, ${props=>props.lgl || '#047C54'}, ${props=>props.lgr || '#00B774'}); 
    padding-left: 16px;
    font-size: 15px;
    font-weight: bold;
    color:#fff;
    cursor:pointer;
`;


export const ContainerHorizontal = styled.div`

    width: 1200px;
    height: auto;
    overflow-x: auto; 
    white-space: nowrap; 
    margin-top: ${props=>props.mt || '10px'};
    padding-bottom: 30px;

    & > * {
        display: inline-block; 
        margin-right: 10px; 
        white-space: normal; 
    }
`;


export const CardServices = styled.div`
    width: 300px;
    height: 100px;
    border: ${({ checkboxservices }) => (checkboxservices ? '1px solid #ccc' : 'none')};
    border-radius: 10px;
    background: ${({ checkboxservices, lgl, lgr }) =>
    checkboxservices
        ? `linear-gradient(to right, ${lgl || '#115e59'}, ${lgr || '#042f2e'})`
        : `linear-gradient(to right, ${lgl || '#047C54'}, ${lgr || '#00B774'})`};

    box-shadow: ${({ checkboxservices }) => (checkboxservices ? '0px 8px 16px rgba(0, 0, 0, 0.1)' : 'none')}; 
    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover{
        background: linear-gradient(to right, ${props=>props.lgl || '#115e59'}, ${props=>props.lgr || '#042f2e'});
        border: none;
        cursor:pointer;
    }

`

export const ContainerFlex = styled.div`

    width: ${props=>props.w || '100%'};
    height: ${props=>props.h || 'auto'};
    display: flex;
    justify-content: ${props=> props.jc || 'space-between'};
    align-items: ${props=> props.align || 'center'};
    margin-top: ${props=>props.mt || '0px'};
    border-bottom: ${props=>props.bb || 'none'};
    padding: ${props=>props.p || '0px'};
    flex-wrap: wrap; 


    @media (max-width: 620px) {
        justify-content: center;
    }
    
`

export const ContainerFlexBetween = styled.div`

    width: ${props=>props.w || '100%'};
    height: ${props=>props.h || 'auto'};
    display: flex;
    justify-content: ${props=> props.jc || 'space-between'};
    align-items: ${props=> props.align || 'center'};
    margin-top: ${props=>props.mt || '0px'};
    border-bottom: ${props=>props.bb || 'none'};
    padding: ${props=>props.p || '0px'};
    flex-wrap: wrap; 
    
`

export const CardDays = styled.div`
    width: 150px;
    height: 200px;
    border: ${({ checkboxday }) => (checkboxday ? '1px solid #ccc' : 'none')};
    border-radius: 10px;
    background: ${({ checkboxday, lgl, lgr }) =>
    checkboxday
        ? `linear-gradient(to right, ${lgl || '#115e59'}, ${lgr || '#042f2e'})`
        : `linear-gradient(to right, ${lgl || '#047C54'}, ${lgr || '#00B774'})`};
        
    box-shadow: ${({ checkboxday }) => (checkboxday ? '0px 8px 16px rgba(0, 0, 0, 0.1)' : 'none')}; 
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor:${(props=> props.pointer) || 'inherit'};

    &:hover{
        background: ${(props) => props.isavailable ? 'linear-gradient(to right, #115e59, #042f2e)' : 'inherit'};
        border: ${(props) => props.isavailable ? 'none' : 'inherit'};
    }

`

export const ContainerRoudend = styled.div`

    width:200px;
    height:200px;
    border-radius: 100%;
    border: ${({ checkBoxCollaborators }) => (checkBoxCollaborators ? '2px solid #ccc' : 'none')};
    background-image: url('${props=>props.url || ''}');
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center center; 

    &:hover{
        width:210px;
        height:210px;
        border: none;
        cursor:pointer;
    }

`

export const CardTime = styled.div`
    width: 292px;
    height: 50px;
    border: ${({ checkboxhour }) => (checkboxhour ? '1px solid #ccc' : 'none')};
    border-radius: 10px;
    background: ${({ checkboxhour, lgl, lgr }) =>
    checkboxhour
        ? `linear-gradient(to right, ${lgl || '#475569'}, ${lgr || '#020617'})`
        : `linear-gradient(to right, ${lgl || '#475569'}, ${lgr || '#64748b'})`};

    display: flex;
    justify-content: ${props=> props.jc || 'center'};
    align-items: ${props=> props.align || 'center'};
    margin-right: ${props=>props.mr || '0px'};
    margin-top: ${props=>props.mt || '0px'};
    margin-bottom: ${props=>props.mb || '0px'};
    margin-left: ${props=>props.ml || '0px'};

    &:hover{
        background: linear-gradient(to right, ${props=>props.lgl || '#475569'}, ${props=>props.lgr || '#020617'});
        cursor:pointer;
    }
   
`

export const StyledTelefoneInput = styled(InputMask)`

    width: 100%;
    height: 45px;
    border-radius: 10px;
    margin-top: ${props => props.mt || '0px'};
    padding-left: 16px;
    font-size: 16px;
    border: none;

    &:focus {
        outline: none; /* Remove a borda ao clicar */
    }

`

export const ButtonMySchedulingTop = styled.div`
    width: 150px;
    height: 45px;
    border-radius: 10px;
    background: linear-gradient(to right, #475569, #64748b);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; 
    font-size: 14px; 
    cursor: pointer; 
    margin-top: ${props => props.mt || '0px'};
`;

export const JaddeAvatar = styled.img`
    width: 30px;
    height: 30px;
`

export const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// Componente StyledRect estilizado
export const StyledRect = styled.div`
  width: 300px;
  height: 100px;
  background-color: #4CAF50; // Cor de fundo verde
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  animation: ${pulse} 2s infinite; // Aplicar animação de pulsação
`;


// Animação para a queda dos confetes
export const drop = keyframes`
  0% { top: -10%; }
  100% { top: 110%; }
`;

// Componente de Confete
export const Confetti = styled.div`
  position: fixed;
  width: 10px;
  height: 10px;
  background-color: ${() => `hsl(${Math.random() * 360}, 100%, 50%)`}; // Cor aleatória
  opacity: 0.6;
  top: -10%;
  left: ${() => `${Math.random() * 100}vw`}; // Posição horizontal aleatória
  animation: ${drop} ${() => `${Math.random() * 3 + 2}s`} linear infinite; // Duração e tipo de animação aleatórios
`;