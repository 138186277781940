import { useState } from "react";
import { BsDatabaseFillCheck, BsThreeDots } from "react-icons/bs"
import { SpinnerCircular } from "spinners-react";
import { concludeRecurrence, deleteRecurrence } from "../api";


export default function ModalConclude({
    setModal, 
    setRender, 
    setSelect, 
    select,
    loadRecurrences
})

{

    const [sppiner, setSpinner] = useState(false);

    function cancel()
    {
        setSelect((prev) => ({...prev, conclude: null}));
        setModal((modal)=>({
            ...modal,
            conclude: false
        }))
    }

    async function conclude()
    {
        setSpinner(true);

        await concludeRecurrence({recurrenceId: select.conclude.id});

        await loadRecurrences();

        setSpinner("success");

        setSelect((prev) => ({...prev, conclude: null}));

        setModal((modal)=>({
            ...modal,
            conclude: false
        }))
    }

    return(
        
        <div className="modal-delete-container">

            <div className="modal-content">

                <div className="header">
                    <button onClick={()=>{setModal((modal)=>({...modal, conclude:false}))}} className="btn-transparent">
                        <BsThreeDots size={30} color="#000"/>
                    </button>
                </div>

                <div className="main">

                    <h3> Faturar recorrência:</h3>
                    <span>{ select.conclude.client.person.name } - {select.conclude.total_value_string}</span>

                </div>

                <div className="footer">
                    <button onClick={()=>{cancel()}} className="cancel">Cancelar</button>
                    <button onClick={()=>{conclude()}} className="conclude">
                        {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <><BsDatabaseFillCheck size={20}/> Registro concluído com sucesso! </> :  "Concluir"}
                    </button>
                </div>

            </div>

        </div>
    )
}