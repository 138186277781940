import { BsArrowLeft, BsCaretRightFill, BsQuestionCircle } from "react-icons/bs";
import './styles/css/style.css';
import { useNavigate } from "react-router-dom";
import { FaRegStar, FaWhatsapp } from "react-icons/fa";

export default function HelpSupport()
{

    const navigate = useNavigate();

    const openWhatsapp = () => {
        // Substitua pelo número de telefone, garantindo o código do país sem adicionar zeros, parênteses ou traços
        // Neste caso, '55' é o código do país para o Brasil seguido do DDD e do número de telefone
        window.open(`https://api.whatsapp.com/send?phone=5531999155023&text=Ol%C3%A1,%20preciso%20de%20suporte`, '_blank');
    };

    return(
        <div className="container-help-suport">

            <div className="header">

                <div onClick={()=>{navigate('/home')}} className="btn-back">
                    <BsArrowLeft size={30} color="#fff" />
                </div>

                <div>
                    <h3>Ajuda Suporte</h3>
                </div>

                <div>
                    <BsQuestionCircle size={25} color="#fff"/>
                </div>

            </div>

            <div className="content">

                <div className="content-title">
                    <h3>Precisa de ajuda?</h3>
                </div>

                <div onClick={()=>{openWhatsapp()}} className="btn-whatsapp">
                    <FaWhatsapp size={100} color="#fff" />
                </div>

                <div className="content-title">
                    <span >Toque no ícone do WhatsApp acima para falar com a gente!</span>
                </div>
 
            </div>

        </div>
    )
}