export const SERVICE_INTERVALS = [
    {name: '5 min', value: 5},
    {name: '10 min', value: 10},
    {name: '15 min', value: 15},
    {name: '20 min', value: 20},
    {name: '25 min', value: 25},
    {name: '30 min', value: 30},
    {name: '35 min', value: 35},
    {name: '40 min', value: 40},
    {name: '45 min', value: 45},
    {name: '50 min', value: 50},
    {name: '55 min', value: 55},
    {name: '60 min', value: 60},
]

export const DAYS_WEEK = [

    {name: 'Segunda-Feira', short: 'SEG'},
    {name: 'Terça-Feira', short: 'TER'},
    {name: 'Quarta-Feira', short: 'QUA'},
    {name: 'Quinta-Feira', short: 'QUI'},
    {name: 'Sexta-Feira', short: 'SEX'},
    {name: 'Sábado', short: 'SÁB'},
    {name: 'Domingo', short: 'DOM'},
]