
import * as React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import Presentation from "../pages/presentation";
import Login from "../pages/login";
import Register from "../pages/register";
import { Scheduling } from "../pages/scheduling";
import { MyScheduling } from "../pages/myscheduling";
import RedefinePassword from "../pages/redefinePassword";

const router = createBrowserRouter([

  { path: "/", element: <Presentation/>},
  { path: "login", element: <Login />},
  { path: "register", element: <Register />},
  { path: "/scheduling", element: <Scheduling/>},
  { path: "/myschedulings", element: <MyScheduling/>},
  { path: "/redefine-password", element: <RedefinePassword/>},

]);


export default function AuthRouter()
{
    return(

        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    )
}