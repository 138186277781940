import { BsDatabaseFillCheck, BsThreeDots} from "react-icons/bs"
import { useState } from "react"
import { SpinnerCircular } from "spinners-react"
import InputMask from "../../../lib/InputMask"
import { postClient } from "../api"
import Validator from "../../../lib/Validator"



export default function ModalAddClient({setModal, user, setClientsList})
{

    const [form, setForm] = useState({

        name: '',
        phone: '',
        email: '',
        birthday: '',
        observation: '',
        adminId: user?.id
    })

    const [errors, setErrors] = useState({
        
        name: '',
        phone: '',
        email: '',
        birthday: '',
        observation: ''
    })

    const [sppiner, setSpinner] = useState(false)

    async function save()
    {
        setSpinner(true);


        const rules = {
            name: 'required|min:4',
            phone: 'required',
            email: 'required',
        };

        const validator = new Validator(rules);

        validator.setCustomMessages({

            'name.required': 'O nome não pode ser vazio.',
            'name.min': 'O nome deve ter pelo menos 4 caracteres.',
            'phone.required': 'Telefone não pode ser vazio.',
            'email.required': 'Email não pode ser vazio.',
        });

        const validationErrors = validator.validate(form);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setSpinner(false);
            return

        } else {
            console.log("Formulário válido, enviar dados:", form);
            setErrors({
                name: '',
                phone: '',
                email: '',
            });
        }

        const client = await postClient(form);

        console.log(client);

        if(client)
        {
            setSpinner("success");
            setClientsList(client);
            setModal((modal)=>({...modal, addClient:false}))

            setForm({
                name: '',
                phone: '',
                email: '',
                birthday: '',
                observation: '',
                adminId: user?.id
            });
            
            setErrors({
                name: '',
                phone: '',
                email: '',
                birthday: '',
                observation: ''
            });
        }

        setSpinner(false);
    }


    function changeInputPhone(str)
    {
        const maskedValue = InputMask.phone(str);

        setForm({
            ...form,
            phone: maskedValue
        })
    }
    function formatarDataNascimento(input) {
        // Remove todos os caracteres não numéricos
        let data = input.replace(/\D/g, '');

        // Limita o comprimento da string a 8 caracteres (ddmmaaaa)
        if (data.length > 8) {
            data = data.slice(0, 8);
        }

        // Adiciona barras conforme o comprimento da string
        if (data.length >= 5) {
            setForm({
                ...form,
                birthday: `${data.slice(0, 2)}/${data.slice(2, 4)}/${data.slice(4)}`
            });
        } else if (data.length >= 3) {
        
            setForm({
                ...form,
                birthday: `${data.slice(0, 2)}/${data.slice(2)}`
            });
        }
    
 
    }

    return(
        
        <div className="modal-collaborator-container">

            <div className="modal-content">

                <div className="header">
                    <button onClick={()=>{setModal((modal)=>({...modal, addClient:false}))}} className="btn-transparent">
                        <BsThreeDots size={30} color="#000"/>
                    </button>
                </div>

                <div className="main">

                    <h3> Adicionar cliente:</h3>

                    <div className="form">

                    <div className="form-group">
                            <span>Nome*:</span>
                            <input value={form.name} onChange={(e)=>{setForm((prev)=>({...prev, name: e.target.value}))}} placeholder="Digite seu nome" type="text"  />
                            <span className="alert-errors">{errors.name}</span>
                        </div>

                        <div className="form-group">
                            <span>Email*:</span>
                            <input value={form.email} onChange={(e)=>{setForm((prev)=>({...prev, email: e.target.value}))}} placeholder="Digite seu email" type="text"  />
                            <span className="alert-errors">{errors.email}</span>
                        </div>

                        <div className="form-group">
                            <span>WhatsApp*:</span>
                            <input value={form.phone} onChange={(e)=>{setForm((prev)=>({...prev, phone: changeInputPhone(e.target.value)}))}} placeholder="Digite seu número e telefone" type="text"  />
                            <span className="alert-errors">{errors.phone}</span>
                        </div>


                        <div className="form-group">
                            <span>Aniversário:</span>
                            <input value={form.birthday} onChange={(e)=>{setForm((prev)=>({...prev, birthday: e.target.value}))}} placeholder="Digite apenas os números - 00/00/0000" type="date"  />
                        </div>

                        <div className="form-group">
                            <button onClick={()=>{save()}}>
                                {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <><BsDatabaseFillCheck size={20}/> Registro salvo com sucesso! </> :  "Salvar"}
                            </button>
                        </div>

                    </div>

                    <div className="space_mobile"></div>

                </div>

                <div className="footer mt-50">
                    
                </div>

            </div>

        </div>
    )
}