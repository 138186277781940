import axios from "axios";
import { API_URL } from "../../config";

export async function postCompanyData(form)
{
    const response = await axios.post(`${API_URL}/company`, form);

    return response.data
}

export async function postOfficeHour(form)
{
    const response = await axios.post(`${API_URL}/officehour`, form);

    return response.data
}

export async function getOfficeHour(adminId)
{
    const response = await axios.get(`${API_URL}/officehour?adminId=${adminId}`);

    return response.data
}

export async function getServices(adminId)
{
    const response = await axios.get(`${API_URL}/services?adminId=${adminId}`);

    return response.data
}

export async function postService(form)
{
    const response = await axios.post(`${API_URL}/services`, form);

    return response.data
}

export async function findService(id)
{
    const response = await axios.get(`${API_URL}/services/${id}`);

    return response.data
}

export async function putService(form, id)
{
    const response = await axios.put(`${API_URL}/services/${id}`, form);

    return response.data.data
}

export async function deleteService(id)
{
    const response = await axios.delete(`${API_URL}/services/${id}`);

    return response.data.data
}

export async function getCollaborators(id)
{
    const response = await axios.get(`${API_URL}/colaborators?adminId=${id}`);

    return response.data;
}

export async function postCollaborator(form)
{
    const response = await axios.post(`${API_URL}/colaborators`, form);

    return response.data
}


export async function findCollaborator(id)
{
    const response = axios.get(`${API_URL}/colaborators/${id}`);

    return (await response).data;
}

export async function putCollaborator(id, DATA)
{
    const response = axios.put(`${API_URL}/colaborators/${id}`, DATA);

    return (await response).data;
}

export async function deleteCollaborator(id)
{
    const response = axios.delete(`${API_URL}/colaborators/${id}`);

    return (await response).data;
}

export async function getClients(adminId)
{
    const response = axios.get(`${API_URL}/client?adminId=${adminId}`);

    return (await response).data;
}


export async function findClient(id)
{
    const response = axios.get(`${API_URL}/client/${id}`);

    return (await response).data;
}

export async function putClient(id, DATA)
{
    const response = axios.put(`${API_URL}/client/${id}`, DATA);

    return (await response).data;
}

export async function postClient(DATA)
{
    const response = axios.post(`${API_URL}/client`, DATA);

    return (await response).data;
}

export async function deleteClient(id)
{
    const response = axios.delete(`${API_URL}/client/${id}`);

    return (await response).data;
}