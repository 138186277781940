export function monthToNumber(monthName) {
    // Object mapping Portuguese month names to numbers
    const months = {
      "janeiro": "01",
      "fevereiro": "02",
      "março": "03",
      "abril": "04",
      "maio": "05",
      "junho": "06",
      "julho": "07",
      "agosto": "08",
      "setembro": "09",
      "outubro": "10",
      "novembro": "11",
      "dezembro": "12"
    };
  
    // Convert the month name to lowercase to avoid case sensitivity issues
    const monthNameLowerCase = monthName.toLowerCase();
  
    // Check if the month name is valid
    if (monthNameLowerCase in months) {
      return months[monthNameLowerCase];
    } else {
      throw new Error("Invalid month name.");
    }
  }