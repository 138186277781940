import axios from "axios";
import { API_URL } from "../../config";

export async function postAuthLogin(email, password)
{
    try {
        const response = await axios.post(`${API_URL}/login-web`, { email:email, password:password });
        return response.data;
    } catch (error) {
        console.error("Error during login:", error);
        throw error; // Lança o erro para que ele possa ser tratado por quem chama essa função
    }
}