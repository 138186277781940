import { BsCaretRightFill } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export default function Menu({setPages})
{

    const navigate = useNavigate();

    return (
        <>
            <div onClick={()=>{navigate('/')}} className="header mt-20">
                <button>
                    <BsArrowLeft size={30} color="#fff" />
                    <span>Voltar</span>
                </button>
            </div>
            
            <ul className="mt-50">
                
                <li onClick={()=>{setPages((pages)=>({
                    ...pages, 
                    menu:false, 
                    company:true,
                    officeHour:false,
                    services: false,
                    collaborators: false
                    
                    }))}}>
                    <span>Dados da empresa</span>
                    <BsCaretRightFill size={20} color="#000"/>
                </li>

                <li onClick={()=>{setPages((pages)=>({
                    ...pages, 
                    menu:false, 
                    company:false,
                    officeHour:true,
                    services: false,
                    collaborators: false
                    }))}}>
                    <span>Expediente</span>
                    <BsCaretRightFill size={20} color="#000"/>
                </li>

                <li onClick={()=>{setPages((pages)=>({
                    ...pages, 
                    menu:false, 
                    company:false,
                    officeHour:false,
                    services: true,
                    collaborators: false
                    }))}}>
                    <span>Serviços</span>
                    <BsCaretRightFill size={20} color="#000"/>
                </li>

                <li onClick={()=>{setPages((pages)=>({
                    ...pages, 
                    menu:false, 
                    company:false,
                    officeHour:false,
                    services: false,
                    collaborators: true
                    }))}}>
                    <span>Colaboradores</span>
                    <BsCaretRightFill size={20} color="#000"/>
                </li>
{/**
 * 
 *                 <li>
                    <span>Configurações do link</span>
                    <BsCaretRightFill size={20} color="#000"/>
                </li>
 * 
 * 
 */}

            </ul>
        </>
    )
}