import { MONTH_NAMES } from "./constants";

export function onLimitCharacters(input, maxLength) {
    if (input?.length <= maxLength) {
      return input;
    }
    return input?.substring(0, maxLength) + "...";
}

export function formatarData(dataString) {
    const data = new Date(dataString);
    const dia = data.getDate();
    const mes = obterNomeMes(data.getMonth()); // Obter o nome do mês
    const ano = data.getFullYear();
  
    return `Acesso até: ${dia} de ${mes}`;
}
  
 export function obterNomeMes(mes) {
    const meses = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];
  
    return meses[mes];
}

export function loadNameMonth(number)
{
  return MONTH_NAMES[number];
}

export function formatCurrency(value) {
  // Converte o valor para um número float para garantir o formato correto
  const number = parseFloat(value);

  // Formata o número como um valor monetário em reais
  return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function formatServiceList(services) {
  // Extrai apenas os nomes dos serviços do array de objetos
  const serviceNames = services.map(service => service.name);

  // Checa se a lista é vazia
  if (!serviceNames.length) {
      return "";
  }

  // Se a lista tem apenas um item, retorna ele mesmo
  if (serviceNames.length === 1) {
      return serviceNames[0];
  }

  // Para listas com mais de um item, processa a formatação
  const lastTwo = serviceNames.slice(-2);
  const others = serviceNames.slice(0, -2);
  const formattedList = [...others, lastTwo.join(" e ")].join(", ");

  return formattedList;
}

