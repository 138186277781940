import { BsArrowLeft, BsCaretRightFill, BsExclamationCircle, BsQuestionCircle } from "react-icons/bs";
import './styles/css/style.css';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SpinnerCircular } from "spinners-react";

export default function FeedBack()
{

    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false);

    const [form, setForm] = useState({
        text: ''
    })

    async function save()
    {
        setSpinner(true);

        if(form.text === '')
        {
            setSpinner(false);
            alert("Você tem que escrever algo!");

            return
        }

        setForm((prev) => ({
            ...prev,
            text: ''
        }))

        setTimeout(() => {
             setSpinner(false);

             alert("Enviado com sucesso!");
        }, 2000);

        
    }

    return(

        <div className="container-feedback">

            <div className="header">

                <div onClick={()=>{navigate('/home')}} className="btn-back">
                    <BsArrowLeft size={30} color="#fff" />
                </div>

                <div>
                    <h3>FeedBack</h3>
                </div>

                <div>
                     <BsExclamationCircle size={25} color="#fff"/>
                </div>

            </div>

            <div className="content">

                <div className="content-text-start">
                    <span>Nos ajude a melhorar! Se você sentiu falta de alguma coisa no App e tem sugestão nos envie um email por favor.</span>
                </div>

                <div className="content-text-start">
                    <h3>Digite sua mensagem:</h3>
                </div>

                <div className="content-text-area">
                    <textarea value={form.text} onChange={(e)=>{setForm((prev) => ({...prev, text: e.target.value }))}}/>
                </div>

                <div className="content-btn-send">
                    <button onClick={()=>{save()}}>
                        {spinner ? <SpinnerCircular size={25} color="#fff"/> : "ENVIAR"}
                    </button>
                </div>

            </div>

            <div className="space_mobile"></div>

        </div>
    )
}