import { useState } from "react";
import { BsDatabaseFillCheck, BsThreeDots } from "react-icons/bs"
import { deleteClient } from "../api";
import { SpinnerCircular } from "spinners-react";


export default function ModalDeleteClient({setModal, user, setClientsList, setClientDelete, clientDelete})
{

    const [sppiner, setSpinner] = useState(false);

    function cancel()
    {
        setClientDelete(null);

        setModal((modal)=>({
            ...modal,
            deleteClient: false
        }))
    }

    async function destroy()
    {

        setSpinner(true);

        const clients = await deleteClient(clientDelete.client_id);

        setClientsList(clients);

        setSpinner("success");

        setClientDelete(null);
        setModal((modal)=>({
            ...modal,
            deleteClient: false
        }))

    }



    return(
        
        <div className="modal-delete-container ">

            <div className="modal-content">

                <div className="header">
                    <button onClick={()=>{setModal((modal)=>({...modal, deleteClient:false}))}} className="btn-transparent">
                        <BsThreeDots size={30} color="#000"/>
                    </button>
                </div>

                <div className="main">

                    <h3> Deletar cliente:</h3>
                    <span>{clientDelete.name}</span>

                </div>

                <div className="footer">
                    <button onClick={()=>{cancel()}} className="cancel">Cancelar</button>
                    <button onClick={()=>{destroy()}} className="delete">
                        {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <><BsDatabaseFillCheck size={20}/> Registro exluído com sucesso! </> :  "Deletar"}
                    </button>
                </div>

            </div>

        </div>
    )
}