import { BsArrowLeft, BsCaretRightFill, BsQuestionCircle } from "react-icons/bs";
import './styles/css/style.css';
import { useNavigate } from "react-router-dom";
import { FaRegComment, FaRegStar, FaWhatsapp } from "react-icons/fa";

export default function Assessment()
{

    const navigate = useNavigate();


    return(

        <div className="container-assessment">

            <div className="header">

                <div onClick={()=>{navigate('/home')}} className="btn-back">
                    <BsArrowLeft size={30} color="#fff" />
                </div>

                <div>
                    <h3>Avaliar</h3>
                </div>

                <div>
                    <FaRegComment size={25} color="#fff"/>
                </div>

            </div>

            <div className="content">

                <div className="content-text-start">
                    <span>Ajude-nos</span>
                </div>

                <div className="content-text-start">
                    <h3>A melhorar este aplicativo =)</h3>
                </div>

                <div className="content-text-start">
                    <span>Compartilhe sua experiência com a Jadde IA, qual nota me daria?</span>
                </div>

                <div className="content-btn-starts">
                    <button>
                        <FaRegStar size={25} color="#fff"/>
                    </button>
                    <button>
                        <FaRegStar size={25} color="#fff"/>
                    </button>
                    <button>
                        <FaRegStar size={25} color="#fff"/>
                    </button>
                    <button>
                        <FaRegStar size={25} color="#fff"/>
                    </button>
                    <button>
                        <FaRegStar size={25} color="#fff"/>
                    </button>
                </div>

            </div>

        </div>
    )
}