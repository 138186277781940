import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Router from './router';
import AuthProvider from './context/AuthProvider';
import echo from './echo';

const App = () => {
  useEffect(() => {
    // Verificar se o ambiente é desktop
    const isDesktop = !/Mobi|Android/i.test(navigator.userAgent);

    if (isDesktop) {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          console.log('Notification permission denied.');
        }
      });

      const showNotification = (title, body) => {
        if ('Notification' in window) {
          new Notification(title, {
            body: body,
            icon: '../images/icon.png'
          });
        } else {
          console.log('Seu navegador não suporta notificações, logo você não receberá avisos de novos agendamentos.');
        }
      };
      const logado_id = localStorage.getItem('logado_id');
      echo.channel('chat')
        .listen('MessageSent', (e) => {
          const regex = /(\d+)\s+(\d+)$/;
          const match = e.message.match(regex);
          const admin_id = match[1];
          const user_id = match[2];
          const newText = e.message.replace(regex, '').trim();

          if (logado_id == admin_id || logado_id == user_id) {
            showNotification('Novo agendamento', newText);
          }

          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
              type: 'scheduleStore',
              data: e.message
            }));
          }
        });

      return () => {
        echo.leaveChannel('chat');
      };
    }
  }, []);

  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
