class DateTime {
    static timeZone = 'America/Sao_Paulo'; // Fuso horário padrão
    static locale = 'pt-BR'; // Locale padrão

    static setTimeZone(timeZone) {
        DateTime.timeZone = timeZone;
    }

    static setLocale(locale) {
        DateTime.locale = locale;
    }

    static getCurrentDay() {
        return new Date().toLocaleString(DateTime.locale, { timeZone: DateTime.timeZone, day: '2-digit' });
    }

    static getCurrentMonth() {
        return new Date().toLocaleString(DateTime.locale, { timeZone: DateTime.timeZone, month: '2-digit' });
    }

    static getMonthName() {
        return new Date().toLocaleString(DateTime.locale, { timeZone: DateTime.timeZone, month: 'long' });
    }

    static getMonthShortName() {
        return new Date().toLocaleString(DateTime.locale, { timeZone: DateTime.timeZone, month: 'short' });
    }

    static getCurrentYear() {
        return new Date().toLocaleString(DateTime.locale, { timeZone: DateTime.timeZone, year: 'numeric' });
    }

    static getCurrentHour() {
        return new Date().toLocaleString(DateTime.locale, { timeZone: DateTime.timeZone, hour: '2-digit', hour12: false });
    }

    static getCurrentMinute() {
        return new Date().toLocaleString(DateTime.locale, { timeZone: DateTime.timeZone, minute: '2-digit' });
    }
}

export default DateTime;