import { useNavigate } from "react-router-dom";
import { Text } from "../../../share/componets/styles";
import { ButtonMySchedulingTop, Confetti, Container, ContainerCenter, ContainerEnd, ContainerStart, GlobalContainer, JaddeAvatar, StyledRect } from "../styled";

export function Concluded({code, email, name, collaboratorName})
{

    const navigate = useNavigate();

    return(
        
        <GlobalContainer>

            {Array.from({ length: 50 }).map((_, index) => (
                <Confetti key={index}/>
            ))}

            <ContainerCenter visible={true}>
                <ContainerEnd>
                    <ButtonMySchedulingTop onClick={()=>{navigate('/myschedulings', { state: { code: code, email: email } })}} mt='20px'>
                        Meus agendamentos
                    </ButtonMySchedulingTop>
                </ContainerEnd>
            </ContainerCenter>

            <ContainerCenter visible={true}>
                <ContainerStart>
                    <Container mt='50px'>

                        <JaddeAvatar src="./images/jadde_menu.png"/>
                        
                        <Text ml='10px' fcolor='#fff' fs='15px'>Uhuull! Prontinho {name} </Text>

                    </Container>
                </ContainerStart>
            </ContainerCenter>

            <ContainerCenter visible={true}>
                <ContainerStart>

                    <Container mt='30px'>

                        <JaddeAvatar src="./images/jadde_menu.png"/>

                            <Text ml='10px' fcolor='#fff' fs='15px'>Seu agendamento foi realizado e o  {collaboratorName} já foi informado sobre o seu horário...</Text>

                    </Container>
 
                </ContainerStart>
            </ContainerCenter>

            <ContainerCenter visible={true}>
                <ContainerStart>

                    <Container mt='30px'>

                        <JaddeAvatar src="./images/jadde_menu.png"/>

                            <Text ml='10px' fcolor='#fff' fs='15px'>Até breve!</Text>

                    </Container>
 
                </ContainerStart>
            </ContainerCenter>
        </GlobalContainer>
    )
}