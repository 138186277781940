import { useState } from "react";
import { BsDatabaseFillCheck, BsThreeDots } from "react-icons/bs"
import { deleteService } from "../../functions";
import { SpinnerCircular } from "spinners-react";


export default function ModalRepeatOfficeHours({
    setIsModalOpen, 
    applyFirstDayTimesToAll
})
{


    return(
        
        <div className="modal-repeat-container">

            <div className="modal-content">

                <div className="header">

                </div>

                <div className="main">
                    <span>Deseja repetir este horário para todos os dias?</span>
                </div>

                <div className="footer">
                    <button onClick={() => {applyFirstDayTimesToAll(); setIsModalOpen((prev) => ({...prev, open: false}))}} className="btn-yes">Sim</button>
                    <button onClick={()=>{setIsModalOpen((prev) => ({...prev, open: false}))}} className="btn-no">Não</button>
                </div>

            </div>

        </div>
    )
}