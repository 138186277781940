import { useState } from "react";
import { BsDatabaseFillCheck, BsThreeDots } from "react-icons/bs"
import { SpinnerCircular } from "spinners-react";
import { deleteRecurrence } from "../api";


export default function ModalDelete({
    setModal, 
    setRender, 
    setSelect, 
    select,
    loadRecurrences
})

{

    const [sppiner, setSpinner] = useState(false);

    function cancel()
    {
        setSelect((prev) => ({...prev, delete: null}));
        setModal((modal)=>({
            ...modal,
            delete: false
        }))
    }

    async function destroy()
    {
        setSpinner(true);

        await deleteRecurrence(select.delete.id);
        await loadRecurrences();

        setSpinner("success");

        setModal((modal)=>({
            ...modal,
            delete: false
        }))
    }

    return(
        
        <div className="modal-delete-container">

            <div className="modal-content">

                <div className="header">
                    <button onClick={()=>{setModal((modal)=>({...modal, delete:false}))}} className="btn-transparent">
                        <BsThreeDots size={30} color="#000"/>
                    </button>
                </div>

                <div className="main">

                    <h3> Deletar recorrência:</h3>
                    <span>{select.delete.client.person.name} - {select.delete.total_value_string}</span>

                </div>

                <div className="footer">
                    <button onClick={()=>{cancel()}} className="cancel">Cancelar</button>
                    <button onClick={()=>{destroy()}} className="delete">
                        {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <><BsDatabaseFillCheck size={20}/> Registro exluído com sucesso! </> :  "Deletar"}
                    </button>
                </div>

            </div>

        </div>
    )
}