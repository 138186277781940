import { BsBoxArrowRight, BsJustify, BsToggleOn } from "react-icons/bs"
import AuthContext from "../../context/AuthContext";
import { useContext, useState } from "react";
import './styles/css/styles.css'
import { FaRegStar } from "react-icons/fa";
import SideBar from "../../componentes/sidebar";
import { FaInfoCircle } from "react-icons/fa";
import { BsToggleOff } from "react-icons/bs";

export default function Fidelity()
{
    const {user, logout} = useContext(AuthContext);

    const [reload, setReload] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const [isCheck, setIsCheck] = useState(false);

    const [countCoin, setCountCoin] = useState(1);

    function decrement() {
        setCountCoin(prevCountCoin => parseInt(prevCountCoin) - 1);
    }

    function increment() {
        setCountCoin(prevCountCoin => parseInt(prevCountCoin) + 1);
    }

    return(
        <div className="fidelity_container">

            {sideBarOpen === true &&
                <SideBar
                    sideBarOpen={sideBarOpen} 
                    setSideBarOpen={setSideBarOpen}
                />
            }

            <div className="fidelity_container_header">
                <button onClick={()=>{setSideBarOpen(!sideBarOpen)}}>
                    <BsJustify size={25} color="#fff" />
                </button>
                <h3>Fidelidade</h3>
                <button onClick={()=>{}}>
                    <FaRegStar size={25} color="#fff"/>
                </button>
            </div>

            <div className="fidelity_container_text">
                <span>Fidelize clientes com o poder das JaddeCoins e aumente o seu faturamento.</span>
            </div>

            <div className="fidelity_container_text">
                <span><a><FaInfoCircle size={16} color="#63b3ed" /> Toque aqui</a> para descobrir o que são as JaddeCoins e aprenda como usa-las para verder mais.</span>
            </div>

            <div onClick={()=>{setIsCheck(!isCheck)}} className="fidelity_container_text_check">
                <span>Ativar/desativar</span>
                {isCheck ? <BsToggleOn size={25} color="#fff"/> : <BsToggleOff size={25} color="#fff" />}
            </div>

            <div className="fidelity_container_text">
                <span>Quantas JaddeCoins o seu cliente deve possuir para receber o prêmio?</span>
            </div>

            <div className="fidelity_container_decrement_increment">
                <div onClick={()=>{decrement()}} className="btn">-</div>
                <input value={countCoin} onChange={(e)=>{setCountCoin(e.target.value)}} className="count" />
                <div onClick={()=>{increment()}} className="btn">+</div>
            </div>

            <div className="fidelity_container_text">
                <span>Qual será o prêmio oferecido em troca das JadeCoins?</span>
            </div>

            <div className="fidelity_container_piker">
                <select>
                    <option>Escolha um prêmio</option>
                    <option>Opção 1</option>
                    <option>Opção 2</option>
                    <option>Opção 3</option>
                    <option>Opção 4</option>
                    <option>Opção 5</option>
                </select>
            </div>

            <div className="fidelity_container_action">
                <button onClick={()=>{alert("Disponível em breve!")}} className="">Adicionar prêmio</button>
            </div>

            <div className="space_mobile"></div>
        </div>
    )
}