import axios from "axios";
import { API_URL } from "../../config";

export async function getClients(id)
{
    const response = axios.get(`${API_URL}/client?adminId=${id}`);

    return (await response).data
}

export async function getClientsCollaboratorLogin(id)
{
    const response = axios.get(`${API_URL}/client?collaboratorId=${id}`);

    return (await response).data
}

export async function getCollaborators(id)
{
    const response = axios.get(`${API_URL}/colaborators?adminId=${id}`);

    return (await response).data
}

export async function getCollaborator(id)
{
    const response = axios.get(`${API_URL}/colaborators/${id}`);

    console.log(`${API_URL}/colaborators?adminId=${id}`);

    return (await response).data
}

export async function postRecurrence(data)
{
    const response = axios.post(`${API_URL}/recurrence`, data);

    return (await response).data;
}

export async function getRecurreces(id)
{
    const response = axios.get(`${API_URL}/recurrence?adminId=${id}`);

    return (await response).data;
}

export async function findRecurrence(id)
{
    const response = axios.get(`${API_URL}/recurrence/${id}`);

    return (await response).data;
}

export async function putRecurrence(id, data)
{
    const response = axios.put(`${API_URL}/recurrence/${id}`, data);

    return (await response).data;
}

export async function deleteRecurrence(id)
{
    const response = axios.delete(`${API_URL}/recurrence/${id}`);

    return (await response).data;
}

export async function concludeRecurrence(data)
{
    const response = axios.post(`${API_URL}/recurrence-validation`, data);

    return (await response).data;
}

export async function undoRecurrence(data)
{
    const response = axios.post(`${API_URL}/recurrence-validation-undo`, data);

    return (await response).data;
}

export async function getRecurrencesFilter(param)
{
    const response  = axios.get(`${API_URL}/getRecurrencesFilter`, {params: param});

    return (await response).data;
}

export async function getRecurrencesFilterCollaboratorLogin(param)
{
    const response  = axios.get(`${API_URL}/getRecurrencesFilterCollaboratorLogin`, {params: param});

    console.log(`${API_URL}/getRecurrencesFilterCollaboratorLogin`);

    return (await response).data;
}