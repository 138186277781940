import {GlobalModalContainer } from "./styles";

import { SpinnerDotted } from "spinners-react";

export default function Reload({bg})
{
    return(
        <GlobalModalContainer display="flex" jc="center" items="center" w="100%" h="100vh" pos="absolute" ind="999" bg={bg ? bg : 'rgba(250,250,250,0.7)'}> 
            <SpinnerDotted size={100} thickness={100} speed={100} color="#36ad47" />
        </GlobalModalContainer>
    )
}