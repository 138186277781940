import { useState } from "react";
import { BsDatabaseFillCheck, BsThreeDots } from "react-icons/bs"
import { SpinnerCircular } from "spinners-react";
import { undoRecurrence } from "../api";


export default function ModalUndo({
    setModal, 
    setRender, 
    setSelect, 
    select,
    loadRecurrences
})

{

    const [sppiner, setSpinner] = useState(false);

    function cancel()
    {
        setSelect((prev) => ({...prev, undo: null}));
        setModal((modal)=>({
            ...modal,
            undo: false
        }))
    }

    async function undo()
    {
        setSpinner(true);

        const DATA = {
            recurrenceId: select.undo.id,
            period: select.period
        }

        const result = await undoRecurrence(DATA);

        console.log(result);

        //setRender((prev) => ({...prev, recurrenceList: result.recurrence}));

        await loadRecurrences();

        setSpinner("success");


        setSelect((prev) => ({...prev, undo: null}));
        setModal((modal)=>({
            ...modal,
            undo: false
        }))

    }

    return(
        
        <div className="modal-delete-container">

            <div className="modal-content">

                <div className="header">
                    <button onClick={()=>{setModal((modal)=>({...modal, undo:false}))}} className="btn-transparent">
                        <BsThreeDots size={30} color="#000"/>
                    </button>
                </div>

                <div className="main">

                    <h3> Desfazer faturamento da recorrência:</h3>
                    <span>{ select.undo.client.person.name } - {select.undo.total_value_string}</span>

                </div>

                <div className="footer">
                    <button onClick={()=>{cancel()}} className="cancel">Cancelar</button>
                    <button onClick={()=>{undo()}} className="delete">
                        {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <><BsDatabaseFillCheck size={20}/> Registro concluído com sucesso! </> :  "Desfazer"}
                    </button>
                </div>

            </div>

        </div>
    )
}