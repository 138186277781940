import { useState } from "react";
import { BsDatabaseFillCheck, BsThreeDots } from "react-icons/bs"
import { deleteService } from "../../functions";
import { SpinnerCircular } from "spinners-react";


export default function ModalDeleteService({setModal, user, setServicesList, setServiceDelete, serviceDelete})
{

    const [sppiner, setSpinner] = useState(false);

    function cancel()
    {
        setServiceDelete(null);
        setModal((modal)=>({
            ...modal,
            deleteService: false
        }))
    }

    async function destroy()
    {

        setSpinner(true);

        const services = await deleteService(serviceDelete.id);

        setServicesList(services);

        setSpinner("success");

        setInterval(() => {
            setServiceDelete(null);
            setModal((modal)=>({
                ...modal,
                deleteService: false
            }))
        }, 2000);

    }



    return(
        
        <div className="modal-delete-container ">

            <div className="modal-content">

                <div className="header">
                        <button onClick={()=>{setModal((modal)=>({...modal, deleteService:false}))}} className="btn-transparent">
                            <BsThreeDots size={30} color="#000"/>
                        </button>
                </div>

                <div className="main">

                    <h3> Deletar serviço:</h3>
                    <span>{serviceDelete.name} - {serviceDelete.value}</span>

                </div>

                <div className="footer">
                    <button onClick={()=>{cancel()}} className="cancel">Cancelar</button>
                    <button onClick={()=>{destroy()}} className="delete">
                        {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <><BsDatabaseFillCheck size={20}/> Registro exluído com sucesso! </> :  "Deletar"}
                    </button>
                </div>

            </div>

        </div>
    )
}