import { Text } from "../../../share/componets/styles";
import { Container, ContainerCenter, ContainerStart, GlobalContainer, JaddeAvatar } from "../styled";

export default function Block()
{
    return(
        <GlobalContainer>
                    <ContainerCenter visible={'true'}>
    
                        <ContainerStart>

                            <Container mt='50px'>
                                <JaddeAvatar src="./images/jadde_menu.png"/>
                                <Text ml='10px' fcolor='#fff' fs='15px'>Opss! Parece que você não tem permissão para acessar este serviço.</Text>
                            </Container>

                        </ContainerStart>

                    </ContainerCenter>
        </GlobalContainer>
    )
}