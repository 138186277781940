import { useState, useEffect, useContext, useRef} from "react";
import { BsArrowRepeat, BsBoxArrowRight, BsCalendar2, BsCaretDownFill, BsClipboardCheck, BsHouseDoor, BsJustify } from "react-icons/bs";
import { MdContentCopy, MdKeyboardArrowDown } from "react-icons/md";
import { onLimitCharacters, loadNameMonth } from "../../../share/functions";
import { MdKeyboardArrowUp } from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { 
    getCollaborators, 
    getDayMonthYearToday, 
    getDayNowCalendar, 
    getMonthDates, 
    getFirstThreeLetters, 
    getOfficeHourAndSchedulingsAdmin, 
    getOfficeHourAndSchedulingsCollaborator, 
    getVerifieldConfig,
    minutosparaTimes,
    getBilligAdmin
} from "../functions";
import SideBar from "../../../componentes/sidebar";
import ModalCollaborators from "./modal-collaborators";
import CopyToClipboard from "react-copy-to-clipboard";
import ModalCalendar from "./modal-calendar";
import GenerateTimeSlots from "../../../lib/GenerateTimeSlots";
import ModalSchedulig from "./modal-scheduling";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import ModalAddScheduling from "./modal-addscheduling";
import Reload from "../../../share/componets/reload";

let selectedScheduleAgenda = null;
let selectedDurationTotal = 0;
export default function Admin()
{

    const {user, logout} = useContext(AuthContext);

    const carrousselRef = useRef(null);
   
    const itemRefs = useRef({});

    const [reload, setReload] = useState(false);

    const [sideBarOpen, setSideBarOpen] = useState(false);
    const [modalCollaboratorsOpen, setModalCollaboratorsOpen] = useState(false);
    const [modalCalendarOpen, setModalCalendarOpen] = useState(false);
    const [modalSchedulingOpen, setModalSchedulingOpen] = useState(false);
    const [modalAddSchedulingOpen, setModalAddSchedulingOpen] = useState(false);
    const [collaboratorsList, setCollaboratorsList] = useState([]);
    const [selectCollaborator, setSelectCollaborator] = useState(null);
    const [selectMonthString, setSelectMonthString] = useState('');
    const [calendarDate, setCalendarDate] = useState(new Date());
    const [daysToMonthList, setDaysToMonthList] = useState([]);
    const [selectDay, setSelectDay] = useState(null);
    const [visibleBilling, setVisibleBilling] = useState(true);
    const [timesSlotsList, setTimesSlotsList] = useState([]);
    const [selectDateNow, setSelectDateNow] = useState(null);
    
    const [copyed, setCopied] = useState(false);

    const navigate = useNavigate();

    const [billing, setBilling] = useState({

        dailyBilling: 'R$ 0.00',
        weeklyBilling: 'R$ 0.00',
        monthlyBilling: 'R$ 0.00',
        dailyAppointmentsCount: 0,
        weeklyAppointmentsCount: 0,
        monthlyAppointmentsCount: 0,
    })

    const [scheduleShow, setScheduleShow] = useState({
        id: null,
        clientName: '',
        collaboratorName: '',
        time: '',
        services: [],
        value: '',
        status: '',
        phone: '',
        dateTime: '',
        collaboratorId: ''
    })

   useEffect(()=>{
    verifieldConfig();
   },[])

   useEffect(() => {
    // Se um dia está selecionado e o item correspondente existe
    if (selectDay && itemRefs.current[selectDay]) {
        const selectedItem = itemRefs.current[selectDay];
        const carroussel = carrousselRef.current;

        // Calcula a posição de scroll necessário para que o item selecionado esteja visível
        const scrollLeft = selectedItem.offsetLeft + selectedItem.offsetWidth / 2 - carroussel.offsetWidth / 2;
        
        // Ajusta o scroll do container do carrossel
        carroussel.scrollLeft = scrollLeft;
        }
    }, [selectDay]);


   function loadDataAdmin()
   {
        const dateNow = getDayMonthYearToday();
        setSelectDateNow(dateNow);
        setSelectMonthString(loadNameMonth(dateNow.month - 1));
        const daysToMonth = getMonthDates(dateNow.year, dateNow.month);

        setSelectDay(dateNow.day)

        setDaysToMonthList(daysToMonth);
        loadOfficeHoursAndSchedulingsAdmin(dateNow);
   }

   async function verifieldConfig()
   {

        setReload(true);

        const response = await getVerifieldConfig(user?.id);

        if(response.error)
        {
            alert(response.error)
            setReload(false);
            navigate('/settings');

            return
        }

        loadDataAdmin();
        loadBilling();

        setReload(false);
   }

   async function loadBilling()
   {
        const response = await getBilligAdmin(user?.id);

        setBilling({
            ...billing,
            dailyBilling: response.dailyBilling,
            dailyAppointmentsCount: response.dailyAppointmentsCount,
            weeklyBilling: response.weeklyBilling,
            weeklyAppointmentsCount: response.weeklyAppointmentsCount,
            monthlyBilling: response.monthlyBilling,
            monthlyAppointmentsCount: response.monthlyAppointmentsCount
        })
   }

   async function loadOfficeHoursAndSchedulingsAdmin(date)
   {
        const officeHoursAndSchedulings = await getOfficeHourAndSchedulingsAdmin(user?.id);

        if(officeHoursAndSchedulings)
        {
            if(officeHoursAndSchedulings.officeHour)
            {
                const daysOfficeHours = JSON.parse(officeHoursAndSchedulings.officeHour.days_hours);

                const dayNow = daysOfficeHours.filter(item => item.name === getFirstThreeLetters(date.dayOfWeek));

                const intervals = officeHoursAndSchedulings.collaboratorsIntervals;

                const generatorSlotsSchedulings = new GenerateTimeSlots(
                    dayNow[0]?.start,
                    dayNow[0]?.end, 
                    parseInt(officeHoursAndSchedulings.officeHour.service_interval), 
                    dayNow[0]?.start,
                    officeHoursAndSchedulings.scheduling, 
                    intervals,
                    date
                )
                const timesSlot = generatorSlotsSchedulings.create();

                setTimesSlotsList(timesSlot);

            }else{
                navigate('/settings');
            }
        }
   }


   async function loadOfficeHoursAndSchedulingsCollaborator(officeHoursAndSchedulings)
   {

        let objDateNow = selectDateNow;

        if (!objDateNow.hasOwnProperty('dayOfWeek')) {
            objDateNow.dayOfWeek = objDateNow.name;
        }

        if(officeHoursAndSchedulings)
        {
            const daysOfficeHoursCollaborator = JSON.parse(officeHoursAndSchedulings.collaborator.days_hours);
            const daysOfficeHoursAdmin = JSON.parse(officeHoursAndSchedulings.officeHour.days_hours);
            const dayNowCollaborator = daysOfficeHoursCollaborator.filter(item => item.name === getFirstThreeLetters(objDateNow.dayOfWeek));
            const dayNowAdmin = daysOfficeHoursAdmin.filter(item => item.name === getFirstThreeLetters(objDateNow.dayOfWeek));
            const intervals = officeHoursAndSchedulings.collaboratorIntervals;
           
            const generatorSlotsSchedulings = new GenerateTimeSlots(
                dayNowCollaborator[0]?.start,
                dayNowCollaborator[0]?.end, 
                parseInt(officeHoursAndSchedulings.officeHour.service_interval), 
                dayNowAdmin[0]?.start,
                officeHoursAndSchedulings.scheduling, 
                intervals,
                selectDateNow
            )

            const timesSlot = generatorSlotsSchedulings.create();

            setTimesSlotsList(timesSlot)
        }
   }

    function loadOfficeHoursAndSchedulingsCollaboratorChangeCalendar(officeHoursAndSchedulings, dateNow)
   {
        if(officeHoursAndSchedulings)
        {
            const daysOfficeHoursCollaborator = JSON.parse(officeHoursAndSchedulings.collaborator.days_hours);
            const daysOfficeHoursAdmin = JSON.parse(officeHoursAndSchedulings.officeHour.days_hours);
            const dayNowCollaborator = daysOfficeHoursCollaborator.filter(item => item.name === getFirstThreeLetters(dateNow.dayOfWeek));
            const dayNowAdmin = daysOfficeHoursAdmin.filter(item => item.name === getFirstThreeLetters(dateNow.dayOfWeek));
            const intervals = officeHoursAndSchedulings.collaboratorIntervals;
            const generatorSlotsSchedulings = new GenerateTimeSlots(
                dayNowCollaborator[0]?.start,
                dayNowCollaborator[0]?.end, 
                parseInt(officeHoursAndSchedulings.officeHour.service_interval), 
                dayNowAdmin[0]?.start,
                officeHoursAndSchedulings.scheduling, 
                intervals,
                dateNow
            )
            const timesSlot = generatorSlotsSchedulings.create();
            setTimesSlotsList(timesSlot)
        }
   }

   function loadOfficeHoursAndSchedulingsAdminChangeCalendar(officeHoursAndSchedulings, dateNow)
   {

        if(officeHoursAndSchedulings)
        {
            const daysOfficeHoursAdmin = JSON.parse(officeHoursAndSchedulings.officeHour.days_hours);
            const dayNowAdmin = daysOfficeHoursAdmin.filter(item => item.name === getFirstThreeLetters(dateNow.dayOfWeek));
            const intervals = officeHoursAndSchedulings.collaboratorsIntervals;
            const generatorSlotsSchedulings = new GenerateTimeSlots(
                dayNowAdmin[0]?.start,
                dayNowAdmin[0]?.end, 
                parseInt(officeHoursAndSchedulings.officeHour.service_interval), 
                dayNowAdmin[0]?.start,
                officeHoursAndSchedulings.scheduling, 
                intervals,
                dateNow
            )
            const timesSlot = generatorSlotsSchedulings.create();
            setTimesSlotsList(timesSlot)
        }
   }

    async function loadCollaborators()
    {
        const result = await getCollaborators(user?.id);
        setModalCollaboratorsOpen(true);
        setCollaboratorsList(result);

    }

    async function changeCollaborator(collaboratorId)
    {
        setCopied(false);
        
        if(collaboratorId === "admin")
        {
            setSelectCollaborator(null);
            loadOfficeHoursAndSchedulingsAdmin(selectDateNow)
            setModalCollaboratorsOpen(false);

            return
        }
        const officeHoursAndSchedulings = await getOfficeHourAndSchedulingsCollaborator(user?.id, collaboratorId);
        console.log(officeHoursAndSchedulings);
        loadOfficeHoursAndSchedulingsCollaborator(officeHoursAndSchedulings)
        setSelectCollaborator(officeHoursAndSchedulings.collaborator);
        setModalCollaboratorsOpen(false);
    }

    async function changeCalendar(date)
    {

        setCalendarDate(date)
        setSelectDateNow(null);

        const dateNow = getDayNowCalendar(date);

        setSelectMonthString(loadNameMonth(dateNow.month - 1));
        setSelectDay(dateNow.day);
        setSelectDateNow(dateNow);
        const daysToMonth = getMonthDates(dateNow.year, dateNow.month);
        setDaysToMonthList(daysToMonth);

        if(selectCollaborator)
        {
            const officeHoursAndSchedulings = await getOfficeHourAndSchedulingsCollaborator(user?.id, selectCollaborator.id);
            loadOfficeHoursAndSchedulingsCollaboratorChangeCalendar(officeHoursAndSchedulings, dateNow)
            setSelectCollaborator(officeHoursAndSchedulings.collaborator);
            setModalCollaboratorsOpen(false);
            setModalCalendarOpen(false);

            return
        }

        const officeHoursAndSchedulings = await getOfficeHourAndSchedulingsAdmin(user?.id);
        loadOfficeHoursAndSchedulingsAdminChangeCalendar(officeHoursAndSchedulings, dateNow)
        setModalCollaboratorsOpen(false);
        setModalCalendarOpen(false);
    }

    async function changeSlotsDate(dateNow)
    {

        const dateNowFormated = {
            dayOfWeek: dateNow.name ? dateNow.name : dateNow.dayOfWeek,
            day: dateNow.day,
            month: dateNow.month,
            year: dateNow.year
        };

        setSelectMonthString(loadNameMonth(dateNow.month - 1));
        setSelectDay(dateNow.day)
        const daysToMonth = getMonthDates(dateNow.year, dateNow.month);
        setDaysToMonthList(daysToMonth);
        setSelectDateNow(dateNowFormated);
        setCalendarDate(null)

        if(selectCollaborator)
        {
            const officeHoursAndSchedulings = await getOfficeHourAndSchedulingsCollaborator(user?.id, selectCollaborator.id);
            loadOfficeHoursAndSchedulingsCollaboratorChangeCalendar(officeHoursAndSchedulings, dateNowFormated)
            setSelectCollaborator(officeHoursAndSchedulings.collaborator);
            setModalCollaboratorsOpen(false);
            setModalCalendarOpen(false);

            return
        }
        const officeHoursAndSchedulings = await getOfficeHourAndSchedulingsAdmin(user?.id);
        loadOfficeHoursAndSchedulingsAdminChangeCalendar(officeHoursAndSchedulings, dateNowFormated)
        setModalCollaboratorsOpen(false);
        setModalCalendarOpen(false);
 
    }

    function schudulingShow(schedule)
    {

        setScheduleShow((prev) => ({
            ...prev,
            id: schedule.id,
            clientName: schedule.client.person.name,
            collaboratorName: schedule.collaborators.person.name,
            time: JSON.parse(schedule.hour).time,
            services: schedule.services,
            value: schedule.value,
            status: schedule.status,
            phone: schedule.client.person.phone,
            dateTime: schedule.appointment_date,
            collaboratorId: schedule.colaborator_id,
            clientId: schedule.client.id,
            clientBlock: schedule.client.block_at 

        }));

        setModalSchedulingOpen(true);
    }

    async function loadUpdate()
    {
        setCopied(false);
        
        if(!selectCollaborator)
        {
            loadOfficeHoursAndSchedulingsAdmin(selectDateNow)
            setModalCollaboratorsOpen(false);

            return
        }
        const officeHoursAndSchedulings = await getOfficeHourAndSchedulingsCollaborator(user?.id, selectCollaborator.id);
        loadOfficeHoursAndSchedulingsCollaborator(officeHoursAndSchedulings)
        setModalCollaboratorsOpen(false);
    }
    return(

        <>


        <div className="home-container">

            {reload && <Reload bg={'rgba(0,0,0,0.8)'}/>}

            {sideBarOpen &&
                <SideBar 
                    sideBarOpen={sideBarOpen} 
                    setSideBarOpen={setSideBarOpen}
                />
            }
            {modalCollaboratorsOpen &&
                <ModalCollaborators 
                    modalCollaboratorsOpen={modalCollaboratorsOpen} 
                    setModalCollaboratorsOpen={setModalCollaboratorsOpen}
                    collaboratorsList={collaboratorsList}
                    changeCollaborator={changeCollaborator}
                    user={user}
                    selectCollaborator={selectCollaborator}
                />            
            }
            {modalCalendarOpen && 
                <ModalCalendar
                    modalCalendarOpen={modalCalendarOpen}
                    setModalCalendarOpen={setModalCalendarOpen}
                    setCalendarDate={setCalendarDate}
                    calendarDate={calendarDate}
                    changeCalendar={changeCalendar}
                />            
            }
            {modalSchedulingOpen &&
                <ModalSchedulig
                    setModalSchedulingOpen={setModalSchedulingOpen}
                    modalSchedulingOpen={modalSchedulingOpen}
                    scheduleShow={scheduleShow}
                    setScheduleShow={setScheduleShow}
                    selectDateNow={selectDateNow}
                    changeSlotsDate={changeSlotsDate}
                    calendarDate={calendarDate}
                    changeCalendar={changeCalendar}
                    daysToMonthList={daysToMonthList}
                    
                />            
            }
            {modalAddSchedulingOpen &&
                <ModalAddScheduling
                    modalAddSchedulingOpen={modalAddSchedulingOpen}
                    setModalAddSchedulingOpen={setModalAddSchedulingOpen}
                    daysToMonthList={daysToMonthList}
                    collaboratorsList={collaboratorsList}
                    user={user}
                    loadUpdate={loadUpdate}
                />            
            }

            <div className="header">

                <button onClick={()=>{setSideBarOpen(!sideBarOpen)}} className="btn-menu">
                    <BsJustify size={25} color="#fff" />
                </button>

                <h3>
                    Início
                </h3>

                <button onClick={()=>{logout()}} className="btn-transparent">
                    <BsBoxArrowRight size={25} color="#fff" />
                </button>

            </div>

            <div onClick={()=>{loadCollaborators()}} className="container-between mt-20">
                
                <img src="./images/icon.png"/>

                <h3>Olá, {user?.name}!</h3>

                <button  className="btn-transparent">
                     <BsCaretDownFill size={25} color="#fff" />
                </button>

            </div>


            <div className="container-between mt-20">

                {selectCollaborator 
                    ?
                    <>
                    <div>
                        <p className="f-white f-bold">Você está na agenda do {selectCollaborator.name}</p>
                        <p className="f-white mt-20"><span className="f-bold">Link: </span> {onLimitCharacters(selectCollaborator.link, 25)}</p>
                    </div>

                    </>
                    :
                    <>
                        <span className="link-mobile"><span className="f-bold" >Seu Link:</span> {onLimitCharacters(user?.link, 20)}</span>
                        <span className="link-desktop"><span className="f-bold" >Seu Link de agendamento:</span> {onLimitCharacters(user?.link, 41)}</span>
                    </>
                }

                <CopyToClipboard text={selectCollaborator ? selectCollaborator.link : user?.link} onCopy={() => setCopied(true)}>
                    <button className="btn-copy">
                       {copyed === false ? "Copiar link" : "Copiado"} {copyed === false ?  <MdContentCopy size={25} color="#fff"/> : <BsClipboardCheck size={25} color="#fff"/>}
                    </button>
                </CopyToClipboard>
              
            </div>

            <div className="container-start mt-20">

                <button onClick={()=>{setModalCalendarOpen(true)}} className="btn-transparent">
                    <BsCalendar2 size={25} color="#fff"/>
                    <h3 className="ml-20">{selectMonthString}</h3>
                </button>

            </div>

            <div ref={carrousselRef} className="carroussel h-70px mt-20">

                {daysToMonthList.map((item, index)=>{
                    return(
                        <div ref={el => itemRefs.current[item.day] = el} onClick={()=>{changeSlotsDate(item)}} key={index} className={selectDay === item.day ? "item-date-active" : "item-date"}>
                        
                            <div className="item-date-content">
                                <p>{item.name}</p>
                                <p>{item.day}</p>
                            </div>

                        </div>
                    )
                })}

            </div>

            <div className="container-end mt-20">

                <button onClick={()=>{setVisibleBilling(!visibleBilling)}} className="btn-transparent">
                    <span>Esconder faturamento</span>
                    {visibleBilling === true ?  <MdKeyboardArrowUp size={25} color="#fff" /> : <MdKeyboardArrowDown size={25} color="#fff" />}
                   
                </button>

            </div>

            <div className={visibleBilling === true ? "container-between mt-10" : "hidden"}>
                <div className="card-faturament-quite">
                    <div className="d-flex">
                        <h3>Hoje</h3>
                        <FaRegMoneyBillAlt  className="ml-10" size={25} color="#fff" />
                    </div>
                    <h3>{billing.dailyBilling}</h3>
                    <span>Quantidade {billing.dailyAppointmentsCount}</span>
                </div>
                <div className="card-faturament-quite">
                <div className="d-flex">
                        <h3>Semana</h3>
                        <FaRegMoneyBillAlt  className="ml-10" size={25} color="#fff" />
                    </div>
                    <h3>{billing.weeklyBilling}</h3>
                    <span>Quantidade {billing.weeklyAppointmentsCount}</span>
                </div>
            </div>

            <div className={visibleBilling === true ? "container-between mt-10" : "hidden"}>
                <div className="card-faturament">
                <div className="d-flex">
                        <h3>Mês</h3>
                        <FaRegMoneyBillAlt  className="ml-10" size={25} color="#fff" />
                    </div>
                    <h3>{billing.monthlyBilling}</h3>
                    <span>Quantidade {billing.monthlyAppointmentsCount}</span>
                </div>
            </div>

            <div className="container-center mt-20">
               {timesSlotsList.length === 0 && <span>Este dia ainda não tem horário expediente cadastrado</span>} 
            </div>

            <div className="container-btn-update">
                <button onClick={()=>{loadUpdate()}} className="btn-update">
                    Atualizar agendamentos
                    <BsArrowRepeat size={25} color="#fff" />
                </button>
            </div>

            <div className="scheduling-container">

            <ul>
                {timesSlotsList.map((item, index) => {
                    let durationTotal = 0;
              
                    let times = minutosparaTimes(item.time);

                    item.schedulings.forEach((schedule) => {
                        const durations = schedule.services.map(service => parseInt(service.duration));
                        durationTotal = durations.reduce((acc, curr) => acc + curr, 0);
                        selectedScheduleAgenda = schedule;
                    });

                    const listItem = (
                        <li key={index}>
                            <span>{item.time}</span>

                            {item.schedulings.map((schedule, scheduleIndex) => (
                                <div
                                    onClick={() => { schudulingShow(schedule) }}
                                    key={scheduleIndex}
                                    className={schedule.status === "Pendente" ? "card-pending" : schedule.status === "Cancelado" ? "card-canceled" : "card-concluded"}
                                >
                                    <p><span>Cliente: </span> {schedule.client.person.name}</p>
                                    <p><span>Colaborador: </span> {schedule.collaborators.person.name}</p>
                                </div>
                            ))}

                            {((times <= selectedDurationTotal) && selectedDurationTotal > 0 ) && (
                                <div
                                    onClick={() => { schudulingShow(selectedScheduleAgenda) }}
                                    className={selectedScheduleAgenda.status === "Pendente" ? "card-pending" : selectedScheduleAgenda.status === "Cancelado" ? "card-canceled" : "card-concluded"}
                                >
                                    <p><span>Cliente: </span> {selectedScheduleAgenda.client.person.name}</p>
                                    <p><span>Colaborador: </span> {selectedScheduleAgenda.collaborators.person.name}</p>
                                </div>
                            )}

                            {item.intervals.map((interval, intervalIndex) => (
                                <div onClick={() => { }} key={intervalIndex} className={"card-interval"}>
                                    <p><span>Colaborador: </span> {interval.name}</p>
                                    <p><span>Evento: </span> {interval.event}</p>
                                </div>
                            ))}
                        </li>
                    );
                    if (durationTotal) {
                        selectedDurationTotal = durationTotal + times;
                    }
                    if(times > selectedDurationTotal){
                        selectedDurationTotal = 0
                    }
                    return listItem;
                })}
            </ul>



            </div>

            <div className="form">
                <button onClick={()=>{setModalAddSchedulingOpen(true)}} className="btn">+ Adicionar agendamento</button>
            </div>

            <div className="mt-50"></div>

        </div>
        

        </>

    )
}