import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import { BsThreeDots } from 'react-icons/bs';
registerLocale('pt-BR', ptBR);

export default function ModalCalendar({
    modalCalendarOpen, 
    setModalCalendarOpen,
    calendarDate,
    changeCalendar
})
{

    return(
        <div className={modalCalendarOpen === true ? 'modal' : 'hidden'}>
            <div className='modal-md-bottom-container-center'>

                <div className='modal-container-block'>
                    <div className="modal-header-flex-center">
                        <button onClick={()=>{setModalCalendarOpen(false)}} className="btn-transparent">
                            <BsThreeDots size={30} color="#000"/>
                        </button>
                    </div>

                    <span className='mt-20'>Selecione uma data:</span>
                    <div className='mt-20'></div>
                    <DatePicker
                        selected={calendarDate}
                        onChange={date => changeCalendar(date)}
                        inline 
                        locale="pt-BR"
                    />
                </div>

            </div>
        </div>

    )
}
