import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRedefinePassword } from "./functions";
import { SpinnerCircular } from "spinners-react";

import './styles/css/style.css';

export default function RedefinePassword()
{

    const navigate = useNavigate();

    const [email, setEmail] = useState('');

    const [success, setSuccess] = useState('');

    const [spinner, setSpinner] = useState(false)

    const [errors, setErrors] = useState({
        email: ''
    })

    async function handleRedefinePassword()
    {
        setSpinner(true);


        if(email === '')
        {
            setErrors((errors)=>({
                ...errors, 
                email: 'Email não pode ser vazio!'
            }))
            setSpinner(false);
            return
        }

        if(!email.includes('@'))
        {
            setErrors((errors)=>({
                ...errors, 
                email: 'Email com formato inválido!'
            }))
            setSpinner(false);
            return
        }

        const response = await getRedefinePassword(email);

        if(response?.success ?? false)
        {
            setSuccess(`Email de redefinição de senha enviado para ${email} com sucesso!`);

            setErrors((errors)=>({
                ...errors, 
                email: ''
            }))
        }

        console.log(response);

        setSpinner(false)
    }

    return(
        <div className="container-redefine-password">

            <div className="header">
                <div>
                    <p>Informe o email de cadastro.</p>
                </div>
                <img src="images/jadde_presentation_two.png"/>
            </div>

            <div className="form">

                <div className="form-group">
                    <input onChange={(e)=>{setEmail(e.target.value)}} value={email} type="email"  placeholder="Digite seu email" className="input-form mt-50"/>
                </div>

                <div className="alert-red">
                    <span>{errors.email}</span>  
                </div>

                <div className="form-group" onClick={()=>{handleRedefinePassword()}} >
                    <button onClick={()=>{}} className="form-group-btn">
                        {spinner === true ? <SpinnerCircular size={20}/> : 'Enviar'}
                    </button>
                </div>

                <div className="form-group">
                    <button className="form-group-btn-outiline" onClick={()=>{navigate('/login')}} >
                         Entrar
                    </button>
                </div>

                <div className="form-group">
                    <div className="alert-red">
                        <span>{success}</span>  
                    </div>
                </div>
                
            </div>
        </div>

    )
}