
import * as React from "react";
import {createBrowserRouter,RouterProvider,} from "react-router-dom";

import Home from '../pages/home';
import Settings from "../pages/settings";
import Client from "../pages/client";
import Recurrence from "../pages/recurrence";
import Billing from "../pages/billing";
import { Scheduling } from "../pages/scheduling";
import { MyScheduling } from "../pages/myscheduling";
import HelpSupport from "../pages/helpSupport";
import Assessment from "../pages/assessment";
import FeedBack from "../pages/feedback";
import Fidelity from "../pages/fidelity";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,

  },
  {
    path: "/register",
    element: <Home/>,
    
  },
  {
    path: "/login",
    element: <Home/>,
    
  },
  {
    path: "/home",
    element: <Home/>,
    
  },
  {
    path: "/settings",
    element: <Settings/>,
    
  },
  {
    path: "/client",
    element: <Client/>,
    
  },
  {
    path: "/recurrence",
    element: <Recurrence/>,
    
  },
  {
    path: "/billing",
    element: <Billing/>,
    
  },

  {
    path: "/help-support",
    element: <HelpSupport/>,
    
  },

  {
    path: "/assessment",
    element: <Assessment/>,
    
  },

  {
    path: "/feedback",
    element: <FeedBack/>,
    
  },

  {
    path: "/fidelity",
    element: <Fidelity/>,
    
  },

  { path: "/scheduling", element: <Scheduling/>},
  
  { path: "/myschedulings", element: <MyScheduling/>},


]);


export default function AppRouter()
{
    return(

        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    )
      
}