import styled from 'styled-components';

export const GlobalContainer = styled.div`

    width: 100%;
    height: auto;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
    background: linear-gradient(to right, #134e4a, #042f2e); 
`;

export const ContainerFull = styled.div`

    width: calc( 100% - ${(props) => props.pl || '0px'});
    height: auto;
    display: ${(props) => props.display || 'block'};
    margin-top: ${(props) => props.mt || '0px'};
    padding-left: ${(props) => props.pl || '0px'};

`

export const ContainerFullCenter = styled.div`

    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${(props) => props.mt || '0px'};

`

export const CardGradient = styled.div`

    width: 95%;
    height: 45px;
    background: linear-gradient(to right, ${props=>props.lgl || '#047C54'}, ${props=>props.lgr || '#00B774'}); 
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

`


export const Modal = styled.div`

    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,0.8);
    position: absolute ;
    z-index: 999;
    bottom:0;
`


export const ModalContent = styled.div`

    width: 100%;
    height: 40%;
    background-color: #fff;
    position: absolute ;
    z-index: 999;
    top: 20%;


`

export const ModalMain = styled.div`

    width: 100%;
    padding-top: 100px;
    padding-left: 16px;
    padding-right: 16px;

`

export const ButtonYes = styled.button`

    width: 40%;
    background-color: red;
    height: 40px;
    border: none;
    cursor: pointer;

`

export const ButtonNo = styled.button`

    width: 40%;
    background-color: #ccc;
    height: 40px;
    border: none;
    cursor: pointer;
`

export const FlexBetween = styled.div`

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
   

`