export function isTodayBeforeOrEqualToGivenDate(dateObj) {
    // Cria a data atual no fuso horário de São Paulo
    const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }));

    // Cria uma data de comparação com a data do objeto, apenas considerando dia, mês e ano
    const compareDate = new Date(dateObj.year, dateObj.month - 1, dateObj.day);

    // Define o horário para ambos os objetos de data como 00:00:00
    now.setHours(0, 0, 0, 0);
    compareDate.setHours(0, 0, 0, 0);

    // Verifica se a data atual é menor ou igual à data do objeto
    return now <= compareDate;
}