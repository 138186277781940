import { useState } from "react";
import Validator from "../../../../lib/Validator";
import { SpinnerCircular } from "spinners-react";
import { postCompanyData } from "../../functions";
import { BsCaretRightFill, BsDatabaseFillCheck } from "react-icons/bs";
import InputMask from "../../../../lib/InputMask";

export default function Main({form, setForm, setPages})
{

    const [errors, setErrors] = useState({
        nameCompany:'',
        phone:'',
        email: ''
    })

    const [sppiner, setSpinner] = useState(false);

    async function store()
    {

        setSpinner(true);

        const rules = {
            nameCompany: 'required|min:4',
            phone: 'required',
            email: 'required|email',
        };

        const validator = new Validator(rules);

        validator.setCustomMessages({
            'nameCompany.required': 'O nome não pode ser vazio.',
            'nameCompany.min': 'O nome deve ter pelo menos 4 caracteres.',
            'phone.required': 'O número telefone não pode ser vazio.',
            'email.required': 'Email não pode ser vazio.',
            'email.email': 'Informe um email válido.',
        });
        
        //const validationErrors = validator.validate(form);

        console.log("Formulário válido, enviar dados:", form);
        setErrors({
            nameCompany: '',
            namePerson: '',
            email: '',
        });


        const result = await postCompanyData(form);

        console.log(result);

        setSpinner("success");

    }


    return(

        <div className="main-form">

            <p className="mt-20">Nome da empresa *</p>
            <input value={form.nameCompany} onChange={(event)=>{setForm((form)=>({...form, nameCompany: event.target.value}))}} type="text" placeholder="Digite o nome de sua empresa"/>
            <p className="alert-errors">{errors.nameCompany}</p>

            <p>Celular whatsapp *</p>
            <input value={form.phone} onChange={(event)=>{setForm((form)=>({...form, phone: InputMask.phone(event.target.value)}))}} type="text" placeholder="Digite o número do seu whatsapp"/>
            <p className="alert-errors">{errors.phone}</p>

            <p>Email *</p>
            <input value={form.email} onChange={(event)=>{setForm((form)=>({...form, email: event.target.value}))}} type="email" placeholder="Digite o email de sua empresa"/>
            <p className="alert-errors">{errors.email}</p>

            <button className="btn-center" onClick={()=>{store()}} >
                {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <> Registro salvo com sucesso! <BsDatabaseFillCheck size={20}/> </> :  "Salvar"}
            </button>

            {sppiner === "success" && <button 

            className="btn-between"

            onClick={()=>{setPages((pages)=>({

                ...pages, 
                menu:false, 
                company:false,
                officeHour:true,
                services: false,
                collaborators: false
                
                }))}}
        
            >
                
                Configurar expediente <BsCaretRightFill size={20} color={"#fff"}/></button>}
            
        </div>
    )
}