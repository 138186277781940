import {useContext, useEffect} from "react";

import AppRouter from "./app.router";
import AuthRouter from "./auth.router";
import AuthContext from "../context/AuthContext";
import Loading from "../pages/loading";



export default function Router()
{
    const { isAuthenticated, loadding} = useContext(AuthContext);

    if(loadding)
    {

        return(
            <Loading/>
        )

    }else{

        return(
        
            isAuthenticated ? <AppRouter /> : <AuthRouter />
        )
    
    }


}