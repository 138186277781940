import axios from "axios";
import { API_URL } from "../../config";

export function formatDateCardSchedulings(date, time) {
    const [year, month, day] = date.split('-');

    const months = [
        'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
        'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];

    const monthName = months[parseInt(month) - 1];

    return `${day} de ${monthName} de ${year}, ${time}`;
}


export function listServices(services) {

    const formatValue = (value) => value.replace(/\u00a0/g, "").replace(".", ",");

    return services.map((service, index) => {
        const name = service.name;
        const value = formatValue(service.value);

        return `${index === services.length - 1 && services.length > 1 ? ' e um ' : 'Um(a) '}${name} - (${value})`;
    }).join(', ');
}

export async function deleteScheduling(code)
{
    
    try {

        const response = await axios.delete(`${API_URL}/scheduling-delete-web/${code}`);

        return response.data

    } catch (error) {
        console.log(error)
    }

}

export async function getSchedulingByEmailByPhone(email, phone)
{
    try {
        const response = await axios.get(`${API_URL}/scheduling?schedulingByClientEmail=${email}&schedulingByClientPhone=${phone}`);

        return response.data;
        
    } catch (error) {

        return error
    }
}

export function formatDayScheduling(appointment_date)
{
    const day = appointment_date.split(' ')[0];

    return day;
}

export function formarHourScheduling(str)
{
    const obj = JSON.parse(str);

    return obj.time
}