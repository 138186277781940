import { BsApp, BsCheckCircleFill, BsDatabaseFillCheck, BsThreeDots, BsToggleOff, BsToggleOn } from "react-icons/bs";
import { useEffect, useState } from "react";
import { SpinnerCircular } from "spinners-react";
import { collaboratorUpdate, putCollaborator, verifyFormTime } from "../../functions";
import { DAYS_WEEK } from "../../constants";
import Validator from "../../../../lib/Validator";
import InputMask from "../../../../lib/InputMask";
import ModalRepeatOfficeHours from "./modalRepeatOfficeHours";
import ModalIntervals from "./modalIntervals";


export default function ModalUpdateCollaborator({
    setModal, 
    user, 
    setCollaboratorsList, 
    servicesList, 
    collaboratorShow, 
    dayHours
})
{
    const [form, setForm] = useState({

        name:'',
        phone:'',
        email: '',
        avatar: null,
        leader: false,
        commission: '',
        adminId: user?.id,
        daysHours: '',
        intervals: '',
        services: []
    });

    const [formIntervals, setFormIntervals] = useState([

        {name: 'SEG', intervals: []},
        {name: 'TER', intervals: []},
        {name: 'QUA', intervals: []},
        {name: 'QUI', intervals: []},
        {name: 'SEX', intervals: []},
        {name: 'SÁB', intervals: []},
        {name: 'DOM', intervals: []},
    ])

    const [active, setActive] = useState({
        intervalDay: null
    })


    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        isOpen:false,
        intervals: false
    });

    const [render, setRender] = useState({
        preview: null
    })

    const [formTime, setFormTime] = useState([
        {name: 'SEG', start: '', end: ''},
        {name: 'TER', start: '', end: ''},
        {name: 'QUA', start: '', end: ''},
        {name: 'QUI', start: '', end: ''},
        {name: 'SEX', start: '', end: ''},
        {name: 'SÁB', start: '', end: ''},
        {name: 'DOM', start: '', end: ''}
    ])

    const [errors, setErrors] = useState({
        
        name:'',
        phone:'',
        email: '',
        leader: '',
        commission: '',
        services: '',
        daysHours: ''
    })

    const [sppiner, setSpinner] = useState(false)

    const [daysSelect, setDaysSelect] = useState([]);

    const [servicesSelect, setServicesSelect] = useState([]);


    // Função para validar o formato de hora
    function isValidTimeFormat(value) {
        const timeFormatRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
        return timeFormatRegex.test(value);
    }

    function checkTimeValidity(formTime) {
        // Verifica se o formato de tempo do primeiro objeto é válido

        if (!isValidTimeFormat(formTime[0].start) || !isValidTimeFormat(formTime[0].end)) {
            return false;
        }

        // Verifica se os campos de start e end dos objetos subsequentes estão vazios
        for (let i = 1; i < formTime.length; i++) {
            if (formTime[i].start !== '' || formTime[i].end !== '') {
                if (formTime[i].start !== ' ' || formTime[i].end !== ' ') {
                    if (formTime[i].start !== null || formTime[i].end !== null) {
                        return false;
                    }
                }
            }
        }

        return true;
    }  
    
    useEffect(() => {
   
        if (checkTimeValidity(formTime)) {
            if(isModalOpen.isOpen === false)
            {
                setIsModalOpen({
                    isOpen: true,
                    open:true
                }); 
            }
                
            } else {
                setIsModalOpen((prev) => ({
                    ...prev,
                    open: false
                })); // Opcional, depende se você quer fechar o modal automaticamente nesse caso
            }
    }, [formTime]);    

    useEffect(()=>{

        if(collaboratorShow)
        {
           
            setForm({
                ...form,
                name: collaboratorShow.name,
                phone: collaboratorShow.phone,
                email: collaboratorShow.email,
                leader: collaboratorShow.leader,
                commission: collaboratorShow.commission,
                services: collaboratorShow.services,
                daysHours: JSON.parse(collaboratorShow.days_hours),
                intervals: JSON.parse(collaboratorShow.intervals)
            })

            setRender((prev)=>({
                ...prev,
                preview: collaboratorShow.avatar
            }))

            if(collaboratorShow.services)
            {
                const services = JSON.parse(collaboratorShow.services);

                const DATA = []; 
                const SERVICES = [];
    
                services.forEach(item => {
    
                    const index = DATA.indexOf(item.id);
        
                    if (index === -1) {
                        DATA.push(item.id);
                        SERVICES.push(item);        
                    } else {
                        DATA.splice(index, 1);
                        SERVICES.splice(index, 1);
                    }
                });

                setServicesSelect(SERVICES);
            }

            if(collaboratorShow.days_hours)
            {
                setFormTime(JSON.parse(collaboratorShow.days_hours));
            } 

            const intervalsData = [
                {name: 'SEG', intervals: []},
                {name: 'TER', intervals: []},
                {name: 'QUA', intervals: []},
                {name: 'QUI', intervals: []},
                {name: 'SEX', intervals: []},
                {name: 'SÁB', intervals: []},
                {name: 'DOM', intervals: []},
            ]

            const intervals = collaboratorShow.intervals ? JSON.parse(collaboratorShow.intervals) : intervalsData

            setFormIntervals(intervals);
        }
        
    },[])


    function applyFirstDayTimesToAll() {
        // Pega os valores de `start` e `end` do primeiro objeto do array
        const { start, end } = formTime[0];
    
        // Atualiza o estado `formTime`, aplicando esses valores a todos os objetos
        setFormTime(formTime.map(day => ({
            ...day,
            start: start,
            end: end
        })));
    }


    async function save()
    {
        setSpinner(true);


        const rules = {
            name: 'required|min:4',
            phone: 'required',
            email: 'required',
            commission: 'required',
        };

        const validator = new Validator(rules);

        validator.setCustomMessages({

            'name.required': 'O nome não pode ser vazio.',
            'name.min': 'O nome deve ter pelo menos 4 caracteres.',
            'phone.required': 'Telefone não pode ser vazio.',
            'email.required': 'Email não pode ser vazio.',
            'commission.required': 'Comissão não pode ser vazio.',
        });

        const validationErrors = validator.validate(form);

        if (Object.keys(validationErrors).length > 0) {

            setErrors(validationErrors);
            setSpinner(false);
            return

        } else {
            setErrors({
                name:'',
                phone:'',
                email: '',
                leader: '',
                commission: '',
                services: '',
                daysHours:''
            });
        }

        if(verifyFormTime(formTime))
        {
            setErrors({
                ...errors,
                daysHours:"Você tem que preencher o horário inicial e final do expediente."
            });

            setSpinner(false);

            return
        }

        const isHourValid = validator.isHourValid(formTime, dayHours);

        if(!isHourValid)
        {
            setErrors({
                ...errors,
                daysHours:"Horário não compatível com o expediente."
            });

            setSpinner(false);

            return
        }

        const formData = new FormData();

        // Adiciona todos os outros campos de dados ao formData
        formData.append('name', form.name);
        formData.append('phone', form.phone);
        formData.append('email', form.email);
        formData.append('leader', form.leader);
        formData.append('commission', form.commission);
        formData.append('daysHours', JSON.stringify(formTime)); 
        formData.append('intervals', JSON.stringify(formIntervals)); 
        formData.append('services', JSON.stringify(servicesSelect)); 
        formData.append('adminId', user?.id);
        formData.append('collaboratorId', collaboratorShow.id);
    
        // Adiciona o arquivo
        if (form.avatar) {
            formData.append('avatar', form.avatar, form.avatar.name);
        }

        const collaborator = await collaboratorUpdate(formData);

        if(collaborator)
        {
            setSpinner("success");
            setCollaboratorsList(collaborator);
            setModal(false);

            setForm({
                name:'',
                phone:'',
                email: '',
                leader: false,
                commission: '',
                adminId: user?.id,
                daysHours: '',
                services: []
            });
            setErrors({
                name:'',
                phone:'',
                email: '',
                leader: '',
                commission: '',
                services: '',
                daysHours:''
            });
        }

        setSpinner(false);
    }

    function changeservicesSelect(service)
    {
        if (servicesSelect.includes(service)) {
            setServicesSelect(daysSelect.filter(d => d !== service));
        } else {
            setServicesSelect([...servicesSelect, service]);
        }
    }

    function changeInputForm(dayName, field, value)
    {
        const maskedValue = InputMask.time(value);

        setFormTime(prevForm => prevForm.map(day => {
            if (day.name === dayName) {
                return { ...day, [field]: maskedValue };
            }
            return day;
        }));
    }

    function changeInputPhone(str)
    {
        const maskedValue = InputMask.phone(str);

        setForm({
            ...form,
            phone: maskedValue
        })
    }

    function changeAvatar(e)
    {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        setForm((prev) => ({
            ...prev,
            avatar: file
        }))
    
        if (!file) return;
    
        reader.onloadend = () => {

            setRender((prev) => ({
                ...prev,
                preview: reader.result
            }));
        };
    
        reader.readAsDataURL(file);
    }

    function intervalsOpen(item)
    {
        setActive((prev) => ({
            ...prev,
            intervalDay: item
        }))

        setIsModalOpen((prev)=>({
            ...prev,
            intervals:true
        }))
    }


    return(
        
        <div className="modal-collaborator-container">

            {isModalOpen.open === true && <ModalRepeatOfficeHours 
                setIsModalOpen={setIsModalOpen}
                applyFirstDayTimesToAll={applyFirstDayTimesToAll}
            
            />}

            {isModalOpen.intervals === true && 
                <ModalIntervals
                    setIsModalOpen={setIsModalOpen}
                    active={active}
                    setForm={setForm}
                    formIntervals={formIntervals}
                    setFormIntervals={setFormIntervals}
                />
            }

            <div className="modal-content">

                <div className="header">
                    <button onClick={()=>{setModal((modal)=>({...modal, updateCollaborator:false}))}} className="btn-transparent">
                        <BsThreeDots size={30} color="#000"/>
                    </button>
                </div>

                <div className="main">

                    <h3> Editar colaborador:</h3>

                    <div className="form">

                        <div className="form-group">
                            <span>Nome:</span>
                            <input value={form.name} onChange={(e)=>{setForm((prev)=>({...prev, name: e.target.value}))}} placeholder="Digite seu nome" type="text"  />
                            <span className="alert-errors">{errors.name}</span>
                        </div>

                        <div className="form-group">
                            <span>Telefone:</span>
                            <input value={form.phone} onChange={(e)=>{changeInputPhone(e.target.value)}} placeholder="Digite seu número whatsapp" type="text"  />
                            <span className="alert-errors">{errors.phone}</span>
                        </div>

                        <div className="form-group">
                            <span>Email:</span>
                            <input value={form.email} onChange={(e)=>{setForm((prev)=>({...prev, email: e.target.value}))}} placeholder="Digite seu melhor email" type="text"  />
                            <span className="alert-errors">{errors.email}</span>
                        </div>

                        <div className="form-group">
                            <span>Avatar:</span>
                            <div>
                                <img src={render.preview === "/img/avatar.png" ? "./images/nao-ha-fotos.png" : render.preview}/>
                            </div>
                            <input onChange={changeAvatar} type="file"/>
                        </div>

                        <div className="form-group">

                            <span>Definir como lider:</span>

                            <div className="w-full d-flex-between">

                                <span>Desativar/Ativar</span>

                                <button onClick={()=>{setForm((form) => ({...form, leader: !form.leader}))}} className="btn-transparent">
                                    {form.leader === false ? <BsToggleOff  size={25} color={'#000'}/> : <BsToggleOn  size={25} color={'#047C54'}/>}
                                </button>

                            </div>
                            
                            <span className="alert-errors">{errors.leader}</span>

                        </div>

                        <div className="w-full mt-20">
                            <span className="mt-20">DIAS E HORÁRIOS</span>
                        </div>

                        <div className="office-hour-form">

                            {DAYS_WEEK.map((item, index) =>{
                                return(

                                    <div key={index} className="item">

                                        <span>{item.short}</span>

                                        <input 
                                            type="text" 
                                            placeholder="Início"
                                            value={(formTime.find(formItem => formItem.name === item.short) || {}).start || ''}
                                            onChange={e => changeInputForm(item.short, 'start', e.target.value)}
                                        />

                                        <span>às</span>

                                        <input
                                            type="text" 
                                            placeholder="Fim"
                                            value={(formTime.find(formItem => formItem.name === item.short) || {}).end || ''}
                                            onChange={e => changeInputForm(item.short, 'end', e.target.value)}
                                        />

                                        <button onClick={()=>{intervalsOpen(item)}}>Intervalos</button>

                                    </div>
                                )
                            })}

                            <span className="alert-errors">{errors.daysHours}</span>

                        </div>



                        <div className="w-full mt-20">
                            <span className="mt-20">SERVIÇOS</span>
                        </div>

                        <div className="services-list">

                            <ul>
                                {servicesList.map((item, index) => {
                                    // Verifica se o item atual está incluído em servicesSelect baseando-se no id
                                    const isSelected = servicesSelect.some(selectedItem => selectedItem.id === item.id);
                                    return(
                                        <li onClick={()=>{changeservicesSelect(item)}} key={index}>
                                            <span>{item.name}</span>
                                            {isSelected ? <BsCheckCircleFill size={20} color={'#047C54'} /> : <BsApp size={20} color={'#000'}/>}
                                        </li>
                                    )
                                })}
                                
                            </ul>

                        </div>

                        <div className="w-full mt-20">
                            <span className="mt-20">DEFINIR COMISSÃO</span>
                        </div>

                        <div className="form-group">
                            <span>Poncentual:</span>
                            <input value={form.commission} onChange={(e)=>{setForm({...form, commission: e.target.value})}} placeholder="Digite o porcentual" type="number"  />
                            <span className="alert-errors">{errors.commission}</span>
                        </div>

                        <div className="form-group">
                            <button onClick={()=>{save()}}>
                                {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <><BsDatabaseFillCheck size={20}/> Registro salvo com sucesso! </> :  "Salvar"}
                            </button>
                        </div>

                    </div>
                    <div className="space-mobile"></div>
                </div>

                <div className="footer mt-50">
                    
                </div>

            </div>

        </div>
    )
}