import { useState } from "react";
import { Text } from "../../../share/componets/styles";
import { deleteScheduling } from "../functions";
import { ButtonNo, ButtonYes, FlexBetween, Modal, ModalContent, ModalMain } from "../styled";
import { SpinnerCircular } from "spinners-react";

export default function ModalCancel({itemDelete, setReload, setModalCancel})
{

    const [spinner, setSpinner] = useState(false);

    async function confirm()
    {
        setSpinner(true)

        const response = await deleteScheduling(itemDelete);

        console.log(response);

        let chatData = JSON.parse(localStorage.getItem('chat'));

        chatData.schedulings = chatData.schedulings.filter(scheduling => scheduling.code !== itemDelete);

        localStorage.setItem('chat', JSON.stringify(chatData));



        setSpinner(false)

        setModalCancel(false);
    }
    return(
        <Modal>
            <ModalContent>

                <ModalMain>

                    <Text>Você tem certeza que deseja cancelar este agendamento?</Text>

                    <FlexBetween>
                        <ButtonNo onClick={()=>{setModalCancel(false)}}>
                            <Text  >Cancelar</Text>
                        </ButtonNo>

                        <ButtonYes onClick={()=>{confirm()}}>
                            <Text fcolor="#fff" >
                                {spinner ? <SpinnerCircular size={20}/> : "Confirmar"} 
                            </Text>
                           
                        </ButtonYes>
                    </FlexBetween>
                </ModalMain>

            </ModalContent>
        </Modal>
    )
}