
import './styles/css/styles.css';
import Menu from './containers/menu';
import { useState } from 'react';
import Company from './containers/company';
import OfficeHour from './containers/officeHour';
import Services from './containers/services';
import Collaborators from './containers/collaborators';

export default function Settings()
{

    const [pages, setPages] = useState({

        menu: true,
        company: false,
        officeHour: false,
        services:false,
        collaborators:false
    })
    
    return(
        <div className="settings-container">

            {pages.menu          &&     <Menu setPages={setPages}/>}
            {pages.company       &&     <Company setPages={setPages}/>}
            {pages.officeHour    &&     <OfficeHour setPages={setPages}/>}
            {pages.services      &&     <Services setPages={setPages}/>}
            {pages.collaborators &&     <Collaborators setPages={setPages}/>}
           
        </div>
    )
}