import { BsThreeDots } from "react-icons/bs";




export default function ModalCollaborators({

    modalCollaboratorsOpen, 
    setModalCollaboratorsOpen, 
    collaboratorsList,
    changeCollaborator,
    user
})

{
    return(
        <div className={modalCollaboratorsOpen === false ? "hidden" : "modal"}>

            <div className={modalCollaboratorsOpen === false ? "hidden" : "modal-md-bottom-container"}>

                <div className="header-flex-center">
                    <button onClick={()=>{setModalCollaboratorsOpen(false)}} className="btn-transparent">
                        <BsThreeDots size={30} color="#000"/>
                    </button>
                </div>

                <div className="modal-main">

                    <span>Administrador</span>

                    <div className="carroussel h-100px mt-50px">

                        <div className="item">
                            
                            <div onClick={()=>{changeCollaborator("admin")}} className="item-image">
                                <img src={user?.avatar === "/img/avatar.png" ? "./images/nao-ha-fotos.png" : user?.avatar}/>
                            </div>

                            <div className="item-name">
                                <span>{user?.name}</span>
                            </div>
                        </div>

                    </div>

                    <span>Colaboradores</span>

                     <div className="carroussel h-100px mt-50px">
                        {collaboratorsList.map((item,index)=>{
                            return(

                                <div className="item" key={index}>

                                    <div  onClick={()=>{changeCollaborator(item.id)}} className="item-image">
                                        <img src={item.avatar === "/img/avatar.png" ? "./images/nao-ha-fotos.png" : item.avatar}/>
                                    </div>

                                    <div className="item-name">
                                        <span>{item.name}</span>
                                    </div>

                                </div>
                                
                            )
                        })}
                    </div>                   
                    
                </div>
                <div className="modal-footer"></div>
                
            </div>
        </div>
    )
}