import { useContext, useEffect, useState } from "react";
import { BsArrowLeft, BsFillBuildingsFill, BsJustify } from "react-icons/bs";
import Main from "./containers/main";
import Address from "./containers/address";
import AuthContext from "../../../context/AuthContext";
import { getCompany } from "../functions";
import { useNavigate } from "react-router-dom";

export default function Company({setPages})
{
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const {user} = useContext(AuthContext);

    const navigate = useNavigate();

    const [page, setPage] = useState({
        main: true,
        address: false,
    })

    const [form, setForm] = useState({

        nameCompany:'',
        namePerson: user?.name,
        phone:'',
        email:'',
        address:'',
        neighborhood:'',
        number:'',
        state:'',
        city:'',
        adminId: user?.id

    })

    useEffect(()=>{
        loadCompany();
    }, [])

    async function loadCompany()
    {
        const result = await getCompany(user?.id);

        if(result)
        {
            setForm({
                ...form,
                nameCompany: result.nameCompany,
                phone: result.phone,
                email: result.email,
                address: result.address,
                neighborhood: result.neighborhood,
                number: result.number,
                state: result.state,
                city: result.city
            })
        }

        console.log(result);
    }

    function changePage(page)
    {
        switch (page) {
            case 1:

                setPage((page)=>({
                    ...page,
                    main: true,
                    address: false,
                }))
                
                break;
            case 2:
                setPage((page)=>({
                    ...page,
                    main: false,
                    address: true,
                }))                
                break;   
        
            default:
                break;
        }
    }
    
    return(
        <div className="company-container">

            <div className="header">

                <div onClick={()=>{setPages((pages)=>({
                    ...pages, 
                    menu: true, 
                    company:false,
                    officeHour:false,
                    services: false,
                    collaborators: false
                    
                    }))}} className="btn-back">
                    <BsArrowLeft size={30} color="#fff" />
                </div>
                
                <div>
                    <h3>Dados da empresa</h3>
                </div>
                <div>
                    <BsFillBuildingsFill size={25} color="#fff"/>
                </div>

            </div>

            <div className="options mt-20">
                <div onClick={()=>{changePage(1)}} className={page.main === true ? "options-btn-active" : "options-btn"}>
                    <span>Principal</span>
                </div>
                <div onClick={()=>{changePage(2)}} className={page.address === true ? "options-btn-active" : "options-btn"}>
                    <span>Endereço</span>
                </div>
            </div>

            {page.main === true && <Main form={form} setForm={setForm} setPages={setPages}/>}
            {page.address === true && <Address form={form} setForm={setForm} setPages={setPages}/>}

            <div className="space-mobile"></div>

        </div>
    )
}