import axios from "axios";
import { API_URL } from "../../config";

export async function getRedefinePassword(email)
{
    try {
        const response = await axios.get(`${API_URL}/redefine-password?email=${email}`)

        return response.data;

    } catch (error) {
        console.log(error)
    }
    

}