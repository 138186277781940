import Reload from "../../share/componets/reload";
import { GlobalContainer } from "./styled";

export default function Loading()
{
    return(

        <GlobalContainer>
            <Reload bg='#013523'/>
        </GlobalContainer>
    )
}