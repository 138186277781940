import { BsArrowLeftRight, BsCurrencyDollar, BsJustify, BsPencilSquare, BsTrash, BsXCircle } from 'react-icons/bs';
import { findRecurrence, getClients, getClientsCollaboratorLogin, getCollaborators, getRecurrencesFilter, getRecurrencesFilterCollaboratorLogin } from './api';
import { useContext, useEffect, useState } from 'react';
import { MONTHS_AND_YAERS_STRING } from './constants';

import SideBar from '../../componentes/sidebar';
import ModalStore from './containers/modal-store';
import AuthContext from '../../context/AuthContext';
import ModalUpdate from './containers/modal-update';
import ModalDelete from './containers/modal-delete';

import './styles/css/style.css';
import ModalConclude from './containers/modal-conclude';
import DateTime from '../../lib/DateTime';
import ModalUndo from './containers/modal-undo';
import { formatCurrency } from '../../share/functions';

export default function Recurrence()
{
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const {user} = useContext(AuthContext);

    const [modal, setModal] = useState({
        calendar: false,
        store: false,
        update: false,
        delete: false,
        conclude: false,
        undo: false
    })

    const [render,  setRender] = useState({
        monthString: '',
        recurrenceList: [],
        billing_pending: 0,
        billing_completed: 0
    })

    const [select,  setSelect] = useState({
        date: '',
        period: '',
        collaborator: null,
        searchClient: '',
        delete: null,
        conclude: null,
        undo: null
    })

    const [data, setData] = useState({
        clients: [],
        collaborators: [],
        recurrence: null
    })

    async function loadClients()
    {
        let result;

        if(user?.type=== "admin")
        {
            result = await getClients(user?.id);

            setData((prev) => ({...prev, clients: result}));

            return
        }

        result = await getClientsCollaboratorLogin(user?.id);

        setData((prev) => ({...prev, clients: result}));

    }

    async function loadCollaborators()
    {
        if(user?.type=== "admin")
        {
            const result = await getCollaborators(user?.id);

            setData((prev) => ({...prev, collaborators: result}));

            return
        }
    }

    async function loadRecurrences()
    {
        let param;
        let response;

        if(user?.type=== "admin")
        {
            param = {
                period:  `${DateTime.getMonthName()} - ${DateTime.getCurrentYear()}`,
                collaboratorId: select.collaborator,
                searchClient: select.searchClient,
                adminId: user?.id
            }

            response = await getRecurrencesFilter(param);

            setRender((prev) => ({
                ...prev,
                recurrenceList: response.recurrences,
                billing_pending: response.payment_sum_value_false,
                billing_completed: response.payment_sum_value_true
                
            }));

            return
        }

        param = {
            period:  `${DateTime.getMonthName()} - ${DateTime.getCurrentYear()}`,
            collaboratorId: user?.id,
            searchClient: select.searchClient,
        }

        response = await getRecurrencesFilterCollaboratorLogin(param);

        setRender((prev) => ({
            ...prev,
            recurrenceList: response.recurrences,
            billing_pending: response.payment_sum_value_false,
            billing_completed: response.payment_sum_value_true
            
        }));

        console.log(response);
       
    }

    useEffect(()=>{

        loadClients();
        loadCollaborators();
        loadRecurrences();
        setSelect((prev) => ({...prev, period: `${DateTime.getMonthName()} - ${DateTime.getCurrentYear()}`}))

    }, [])


    async function show(id)
    {
        const result = await findRecurrence(id);

        setData((prev) => ({
            ...prev,
            recurrence: result
        }))

        setModal((prev) => ({
            ...prev,
            update: true
        }))
    }

    async function destroy(item)
    {
        setSelect((prev) => ({
            ...prev,
            delete: item
        }))

        setModal((prev) => ({
            ...prev,
            delete: true
        }))
    }

    async function conclude(item)
    {

        setSelect((prev) => ({
            ...prev,
            conclude: item
        }));

        setModal((prev) => ({...prev, conclude: true}))
    }

    async function undo(item)
    {

        setSelect((prev) => ({
            ...prev,
            undo: item
        }));

        setModal((prev) => ({...prev, undo: true}))
    }

    async function changeDate(period)
    {
        setSelect((prev)=>({...prev, period: period}));  

        let param;
        let response;

        if(user?.type=== "admin")
        {
            param = {
                period: period,
                collaboratorId: select.collaborator,
                searchClient: select.searchClient,
                adminId: user?.id
            }
            
            response = await getRecurrencesFilter(param);
    
            setRender((prev) => ({
                ...prev,
                recurrenceList: response.recurrences,
                billing_pending: response.payment_sum_value_false,
                billing_completed: response.payment_sum_value_true
                
            }));

            return
        }

        param = {
            period: period,
            collaboratorId: user?.id,
            searchClient: select.searchClient,
        }

        response = await getRecurrencesFilterCollaboratorLogin(param);

        setRender((prev) => ({
            ...prev,
            recurrenceList: response.recurrences,
            billing_pending: response.payment_sum_value_false,
            billing_completed: response.payment_sum_value_true
            
        }));

        console.log(response);
        

    }    

    async function changeCollaborator(item)
    {
        setSelect((prev) => ({
            ...prev,
            collaborator: item === 'Todos' ? null : item
        }))

        const param = {
            period: select.period,
            collaboratorId: item === 'Todos' ? null : item,
            searchClient: select.searchClient,
            adminId: user?.id
        }

        const response = await getRecurrencesFilter(param);

        setRender((prev) => ({
            ...prev,
            recurrenceList: response.recurrences,
            billing_pending: response.payment_sum_value_false,
            billing_completed: response.payment_sum_value_true
            
        }));
    }

    async function changeClient(item)
    {
        setSelect((prev) => ({
            ...prev,
            searchClient: item
        }))

        let param;
        let response;

        if(user?.type=== "admin")
        {
            param = {
                period: select.period,
                collaboratorId: select.collaborator,
                searchClient: item,
                adminId: user?.id
            }

            response = await getRecurrencesFilter(param);

            setRender((prev) => ({
                ...prev,
                recurrenceList: response.recurrences,
                billing_pending: response.payment_sum_value_false,
                billing_completed: response.payment_sum_value_true
                
            }));

            return
        }

        param = {
            period: select.period,
            collaboratorId: user?.id,
            searchClient: item,
        }

        response = await getRecurrencesFilterCollaboratorLogin(param);

        setRender((prev) => ({
            ...prev,
            recurrenceList: response.recurrences,
            billing_pending: response.payment_sum_value_false,
            billing_completed: response.payment_sum_value_true
            
        }));

        console.log(response);

    }

    return(

        <div className='container-recurrence'>

            {sideBarOpen === true &&
                <SideBar
                    sideBarOpen={sideBarOpen} 
                    setSideBarOpen={setSideBarOpen}
                />
            }
            
            {modal.store === true && <ModalStore 
                setModal={setModal} 
                data={data} 
                setRender={setRender} 
                loadRecurrences={loadRecurrences}
            />}

            {modal.update === true && 
                <ModalUpdate 
                    setModal={setModal} 
                    data={data} 
                    setRender={setRender}
                    loadRecurrences={loadRecurrences}
                />
            }

            {modal.delete === true && <ModalDelete 
                setModal={setModal} 
                data={data} 
                setRender={setRender} 
                setSelect={setSelect} 
                select={select}
                loadRecurrences={loadRecurrences}
            /> }

            {modal.conclude === true && <ModalConclude
                setModal={setModal} 
                data={data} 
                setRender={setRender} 
                setSelect={setSelect} 
                select={select}
                loadRecurrences={loadRecurrences}
            /> }

            {modal.undo === true && <ModalUndo
                setModal={setModal} 
                data={data} 
                setRender={setRender} 
                setSelect={setSelect} 
                select={select}
                loadRecurrences={loadRecurrences}
            /> }           

            <div className='header'>

                <button onClick={()=>{setSideBarOpen(true)}} className="btn-transparent ">
                    <BsJustify size={25} color="#fff" />
                </button>

                <h3>Recorrência</h3>

                <div className="btn-transparent">
                    <BsArrowLeftRight size={25} color="#fff" />
                </div>

            </div>

            <div className="content">

                <div className='w-full'>
                    <p>Período</p>
                </div>

                <div className='calendar  mt-10'>

                    <select value={select.period} onChange={(e)=>{changeDate(e.target.value)}}>

                        {MONTHS_AND_YAERS_STRING.map((item, index) =>{
                            return(
                                <option key={index}>
                                    {item.month} - {item.year}
                                </option>
                            )
                        })}

                    </select>

                </div>

                {user?.type=== "admin" &&
                    <>
                        <div className='w-full mt-10'>
                            <p>Colaboradores</p>
                        </div>

                        <div className='w-full mt-10'>

                            <select onChange={(e)=>{changeCollaborator(e.target.value)}}>

                                <option>Todos</option>

                                {data.collaborators.map((item, index) =>{
                                    return(
                                        <option value={item.id} key={index}>{item.name}</option>
                                    )
                                })}
                            </select>

                        </div>                
                    </>
                }

 

                <div className='w-full mt-10'>
                    <p>Clintes</p>
                </div>

                <div className='w-full mt-10'>
                    <input value={select.searchClient} onChange={(e)=>{changeClient(e.target.value)}} placeholder='Pesquisar cliente pelo nome' type='text'/>
                </div>

                <div className='indicators'>

                    <div className='card'>
                        <div className='w-full d-flex-center mt-20'>
                            <p>Pendentes</p>
                        </div>
                        <div className='w-full d-flex-center mt-10'>
                            <p>{formatCurrency(render.billing_pending)}</p>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='w-full d-flex-center mt-20'>
                            <p>Faturado</p>
                        </div>
                        <div className='w-full d-flex-center mt-10'>
                            <p>{formatCurrency(render.billing_completed)}</p>
                        </div>
                    </div>

                </div>

                <div className='w-full mt-20'>
                    <span>Lista de clientes recorrentes</span>
                </div>



                {render.recurrenceList.length === 0 &&
                    <div className='w-full mt-20'>
                        <span>Nenhum registro de recorrência encontrado</span>
                     </div>
                }

                <div className='w-full mt-20'>
                    {render.recurrenceList.length > 0 && <p>Pendentes</p>}
                </div>


                <div className='list'>
                    <ul>

                        {render.recurrenceList.map((item, index) => {
                            if(item.payment === 0)
                            {
                                return(

                                    <li key={index}>
                                        
                                        <div className='item'>
    
                                            <div>
                                                <span>{item.client.person.name} -</span>
    
                                                <span className='ml-10'>{item.total_value_string}</span>
    
                                                <div>
                                                    <p>{item.services.map(service => service.name).join(', ')}</p>
                                                </div>
                                            </div>
    
                                        </div>
    
                                        <div className="item">
    
                                            <button onClick={()=>{conclude(item)}}>
                                                <BsCurrencyDollar size={20}/>
                                            </button>
    
                                            <button onClick={()=>{show(item.id)}}>
                                                <BsPencilSquare size={20}/>
                                            </button>
    
                                            <button onClick={()=>{destroy(item)}}>
                                                <BsTrash size={20}/>
                                            </button>
    
                                        </div>
                                        
    
                                    </li>
                                )
                            }
                        })}

                    </ul>
                </div>

                <div className='w-full mt-20'>
                    {render.recurrenceList.length > 0 && <p>Faturado</p>}
                </div>

                <div className='list'>
                    <ul>

                        {render.recurrenceList.map((item, index) => {

                            if(item.payment === 1)
                            {
                                return(

                                    <li key={index}>
                                        
                                        <div className='item'>
    
                                            <div>
                                                <span>{item.client.person.name} -</span>
    
                                                <span className='ml-10'>{item.total_value_string}</span>
    
                                                <div>
                                                    <p>{item.services.map(service => service.name).join(', ')}</p>
                                                </div>
                                            </div>
    
                                        </div>
    
                                        <div className="item">
    
                                            <button onClick={(e)=>{undo(item)}}>
                                                <BsXCircle size={20}/>
                                            </button>
    
                                            <button onClick={(e)=>{show(item.id)}}>
                                                <BsPencilSquare size={20}/>
                                            </button>
    
                                            <button onClick={()=>{destroy(item)}}>
                                                <BsTrash size={20}/>
                                            </button>
    
                                        </div>
                                        
    
                                    </li>
                                )
                            }

                        })}

                    </ul>
                </div>

                <button onClick={()=>{setModal((prev) => ({...prev, store: true}))}}>Adicionar recorrência</button>

                <div className="space_mobile"></div>


            </div>



        </div>
    )
}