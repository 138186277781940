class InputMask {
    static time(input) {
        // Remove todos os caracteres que não sejam números
        const cleanValue = input.replace(/\D/g, '');

        // Formata para o formato de hora (HH:MM) enquanto o usuário digita
        let formattedTime = '';
        if (cleanValue.length > 0) {
            formattedTime = cleanValue.slice(0, 2);
            if (cleanValue.length > 2) {
                formattedTime += ':' + cleanValue.slice(2, 4);
            }
        }

        return formattedTime;
    }

    static isValidTime(input) {
        // Verifica se o valor tem o formato '00:00'
        return /^\d{0,2}:\d{0,2}$/.test(input);
    }

    static moneybr(value) {
        // Removes any character that is not a number
        value = value.replace(/\D/g, '');

        // Ensure there's at least one digit before the comma
        if (value.length < 3) {
            value = value.padStart(3, '0');
        }

        // Splits the value into real and cent parts
        const real = value.slice(0, -2);
        const cent = value.slice(-2);

        // Remove leading zeros from the real part and format
        const formattedValue = `R$ ${parseInt(real, 10)},${cent}`;

        return formattedValue;
    }

    static formatStringToMoney(valor) {
        // Convertendo para um número de ponto flutuante
        valor = parseFloat(valor);
    
        // Verificando se o valor é um número
        if (isNaN(valor)) {
            return 'Valor inválido';
        }
    
        // Formatando para Reais e Centavos
        const formatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
    
        return formatado;
    }

    static phone(input) {
        // Remove todos os caracteres que não sejam números
        const cleanValue = input.replace(/\D/g, '');

        // Formata para o formato de telefone
        let formattedPhone = '';
        if (cleanValue.length > 0) {
            // Adiciona o parêntese no início
            formattedPhone += '(' + cleanValue.slice(0, 2);
            // Verifica se tem dígitos suficientes para a segunda parte
            if (cleanValue.length > 2) {
                formattedPhone += ') ' + cleanValue.slice(2, 6);
            }
            // Verifica se deve adicionar o traço
            if (cleanValue.length > 6) {
                // Verifica se é um número de celular com 9 dígitos na parte local
                if (cleanValue.length > 10) {
                    formattedPhone = '(' + cleanValue.slice(0, 2) + ') ' + cleanValue.slice(2, 7) + '-' + cleanValue.slice(7, 11);
                } else {
                    formattedPhone += '-' + cleanValue.slice(6);
                }
            }
        }

        return formattedPhone;
    }

    static formatDate(input)
    {
        // Filtra apenas números e limita a 8 caracteres para DDMMYYYY
        let nums = input.replace(/\D/g, '').substring(0, 8);

        // Formata como DD/MM/YYYY se houver números suficientes
        if (nums.length > 2) {
            nums = nums.substring(0, 2) + '/' + nums.substring(2);
        }
        if (nums.length > 5) {
            nums = nums.substring(0, 5) + '/' + nums.substring(5);
        }

        return nums;
    }

    static formatDateTimeApontament(dateTimeStr) {
        // Primeiro, criamos um objeto Date a partir da string de entrada
        let date = new Date(dateTimeStr);
      
        // Opções para formatar a data no formato desejado
        let dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        
        // Opções para formatar a hora no formato desejado
        let timeOptions = { hour: '2-digit', minute: '2-digit' };
        
        // Traduzindo para português do Brasil
        let formattedDate = date.toLocaleDateString('pt-BR', dateOptions);
        let formattedTime = date.toLocaleTimeString('pt-BR', timeOptions);
        
        // Substituindo a vírgula e ajustando o formato da data
        formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1).replace(',','');
        
        // Retornando o objeto com a data e hora formatados
        return {
          date: formattedDate,
          time: formattedTime
        };
    }
}

export default InputMask;
