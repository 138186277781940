import axios from "axios";
import { API_URL } from "../../config";
import moment from 'moment-timezone';


export function generateDaysList(availability, daysLimited) {
    
    const DAYS = [];
    const weekdays = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const shortWeekdays = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];

    const today = new Date(); // Obtém a data atual
    for (let i = 0; i < daysLimited; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i); // Adiciona 'i' dias à data atual

        const dayName = weekdays[date.getDay()];
        const shortDayName = shortWeekdays[date.getDay()];
        const formattedDate = date.toISOString().slice(0, 10); // Obtém a data no formato 'YYYY-MM-DD'

        let day;
        if (date.toDateString() === today.toDateString()) {
            day = 'Hoje';
        } else {
            day = date.getDate();
        }

        // Encontra a disponibilidade para o dia da semana atual
        const dayAvailability = availability.find(item => item.name === shortDayName);

        // Define available como true se existir horário definido (start e end não nulos)
        const isAvailable = dayAvailability && dayAvailability.start !== null && dayAvailability.end !== null;

        DAYS.push({ name: dayName, short: shortDayName, available: isAvailable, date: formattedDate, day });
    }

    return DAYS;
}

export function getCollaborator()
{
    const COLLABORATORS = [

        {name: 'Alex Barreto', check:false, photo: 'https://img.freepik.com/vetores-premium/avatar-homem-barba_96853-399.jpg?w=900'},
        {name: 'Alex Barreto', check:false, photo: 'https://img.freepik.com/vetores-premium/avatar-homem-barba_96853-399.jpg?w=900'},
        {name: 'Alex Barreto', check:true,  photo: 'https://img.freepik.com/vetores-premium/avatar-homem-barba_96853-399.jpg?w=900'},
        {name: 'Alex Barreto', check:false, photo: 'https://img.freepik.com/vetores-premium/avatar-homem-barba_96853-399.jpg?w=900'},
        {name: 'Alex Barreto', check:false, photo: 'https://img.freepik.com/vetores-premium/avatar-homem-barba_96853-399.jpg?w=900'},
        {name: 'Alex Barreto', check:false, photo: 'https://img.freepik.com/vetores-premium/avatar-homem-barba_96853-399.jpg?w=900'},
    ]

    return COLLABORATORS;
}

export async function getData(code)
{

    const response = await axios.get(`${API_URL}/client/scheduling/${code}`);


    if(!response.data.error)
    {
        const services      = response.data.collaborator.services;
        const collaborator  = response.data.collaborator;
        const schedulings   = response.data.schedulings;
        const officeHour    = response.data.officeHour;
    
        return {
            services: services,
            collaborator: collaborator,
            schedulings: schedulings,
            officeHour: officeHour
        };
    }

    return false;


}

export function genereteTimes(day, collaboratorDaysHours, schedulings, officeHour, intervals, totalDuration)
{

    const interval = parseInt(officeHour.service_interval);
    const salonDaysHours = JSON.parse(officeHour.days_hours);

    const timeToMinutes = (time) => {
        if (!time) return null;
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const minutesToTime = (minutes) => {
        const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
        const mins = (minutes % 60).toString().padStart(2, '0');
        return `${hours}:${mins}`;
    };

    const salonDaySchedule = salonDaysHours.find(d => d.name === day.short); //Horário do dia do salão

    const employeeDaySchedule = collaboratorDaysHours.find(d => d.name === day.short); // Horário do dia do colaborador
    if (!salonDaySchedule || !employeeDaySchedule) { //Verifica se o dia está disponível
        return 'Horário não disponível para este dia.';
    }

    const salonStart = timeToMinutes(salonDaySchedule.start);
    const employeeStart = timeToMinutes(employeeDaySchedule.start);
    const employeeEnd = timeToMinutes(employeeDaySchedule.end);

    if (employeeStart === null || employeeEnd === null) {
        return 'Horário do colaborador não definido para este dia.';
    }

    let firstSlot = salonStart;
    while (firstSlot < employeeStart) { //localiza o primeiro slot do colaborador
        firstSlot += interval;
    }

    if (firstSlot > employeeEnd) {
        return 'Nenhum slot disponível dentro do horário do colaborador.';
    }

    const dayIntervals = intervals.find(d => d.name === day.short)?.intervals || [];

    const isWithinInterval = (time) => {
        return dayIntervals.some(interval => {
            const start = timeToMinutes(interval.inicio);
            const end = timeToMinutes(interval.fim);
            return time >= start && time < end;
        });
    };

    let currentTime = firstSlot;
    let slots = [];

    const dateString = day.date //`${day.year}-${String(day.month).padStart(2, '0')}-${String(day.day).padStart(2, '0')}`;
    let totalDurationServices;
    let validadteNextHours = false;
    let currentTimeTemp = 0;
    while (currentTime + interval <= employeeEnd) {

        const currentSlotTime = minutesToTime(currentTime); //slot

        const isSlotTaken = schedulings.some(scheduling => {

            const appointmentDate = new Date(scheduling.appointment_date);
            const appointmentDateString = `${appointmentDate.getFullYear()}-${String(appointmentDate.getMonth() + 1).padStart(2, '0')}-${String(appointmentDate.getDate()).padStart(2, '0')}`;
            const schedulingTime = JSON.parse(scheduling.hour).time;
            const durations = scheduling.services.map(service => parseInt(service.duration));
            totalDurationServices = durations.reduce((acc, curr) => acc + curr, 0);
          
            if(appointmentDateString === dateString && schedulingTime === currentSlotTime){
                validadteNextHours = true;
                currentTimeTemp = (currentTime+totalDurationServices);
                return true;
            }
         
            return false;
        });
        if(validadteNextHours && currentTime <= currentTimeTemp){
            slots.push({ slot: minutesToTime(currentTime), available: false });
        }
        else{
            if (!isWithinInterval(currentTime) && !isSlotTaken) {
                slots.push({ slot: currentSlotTime, available: true });
            } else {
                slots.push({ slot: currentSlotTime, available: false });
            }
            validadteNextHours = false;
            currentTimeTemp = 0;
        }
        currentTime += interval;
     
    }


    return slots;
}

export function formatarData(dataString) {
    
    const opcoes = {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC'
    };

    // Adiciona 'T00:00' para definir explicitamente a hora como meia-noite e usar o fuso horário local
    const data = new Date(dataString + 'T00:00');

    return new Intl.DateTimeFormat('pt-BR', opcoes).format(data);
}

export function listServices(services) {

    const formatValue = (value) => value.replace(/\u00a0/g, "").replace(".", ",");

    return services.map((service, index) => {
        const name = service.name;
        const value = formatValue(service.value);

        return `${index === services.length - 1 && services.length > 1 ? ' e um ' : 'Um(a) '}${name} - (${value})`;
    }).join(', ');
}


export function getCode()
{
    const string = window.location.href;

    if(string.includes('?code='))
    {
        const code = string.split('?code=')[1];

        return code;
    }

   return false;    
}

export async function storeScheduling(data)
{
    const response = await axios.post(`${API_URL}/scheduling`, data);

    return response.data

}

export function transformStringDate(dateString) {
    // Validate if the input is a non-empty string
    if (typeof dateString !== 'string' || !dateString.trim()) {
        throw new Error('Input must be a non-empty string.');
    }

    // Create a date object from the string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        throw new Error('The provided date is invalid.');
    }

    // Days of the week in Portuguese
    const daysOfWeek = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];

    // Get the name of the day of the week
    const dayOfWeek = daysOfWeek[date.getUTCDay()];

    // Get the day, month, and year
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Remember that months start from zero
    const year = date.getUTCFullYear();

    // Format the date in the desired pattern
    const formattedDate = `${dayOfWeek} ${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return formattedDate;
}

export async function getClient(email, phone)
{
    const response = await axios.get(`${API_URL}/client?clientByEmail=${email}&clientByPhone=${phone}`);

    return response.data;
}
export async function getSchedulingByEmailByPhone(email, phone)
{


    try {
        const response = await axios.get(`${API_URL}/scheduling?schedulingByClientEmail=${email}&schedulingByClientPhone=${phone}`);

        return response.data;

    } catch (error) {

        return error
    }
}

export function verifieldCheckBoxHour(available, time, date) {
    if (!available) {
        return false;
    }

    const saoPauloTimeZone = 'America/Sao_Paulo';
    const isoDateString = `${date}T${time}:00.000`;
    
    // Cria a data/hora alvo no fuso horário de São Paulo usando Moment.js
    const targetDateTimeSP = moment.tz(isoDateString, saoPauloTimeZone);

    // Obtém a data e hora atuais no fuso horário de São Paulo
    const currentDateTimeSP = moment.tz(saoPauloTimeZone);
    return targetDateTimeSP >= currentDateTimeSP;
}



export async function getRecurrenceByClientId(clientId, collaboratorId)
{

    try {

        const response = await axios.get(`${API_URL}/getRecurrenceByClientId?clientId=${clientId}&collaboratorId=${collaboratorId}`);

        return response.data;
        
    } catch (error) {

        return error
    }
}
