class GenerateTimeSlots {
    
    constructor(startHour, endHour, interval, adminStartHour, scheduling, intervals , date) {
        this.startHour = startHour;
        this.endHour = endHour;
        this.interval = interval;
        this.adminStartHour = adminStartHour;
        this.scheduling = scheduling;
        this.intervals = intervals
        this.date = date;
    }

    create() {
        const timeSlots = [];
        
        if (this.startHour && typeof this.startHour === 'string' && this.endHour && typeof this.endHour === 'string') {
            const dateNow = this.formatDate(this.date);
            const dayOfWeek = this.date.dayOfWeek.replace('.', '');
            
            const filterSchedulings = this.filterSchedulingsDate(dateNow);

            const [startHourAdminStr, startMinuteAdminStr] = this.adminStartHour.split(':');
            const [startHourStr, startMinuteStr] = this.startHour.split(':');
            const [endHourStr, endMinuteStr] = this.endHour.split(':');

            const startHourNumAdmin = parseInt(startHourAdminStr, 10);
            const startHourNum = parseInt(startHourStr, 10);
            const endHourNum = parseInt(endHourStr, 10);
            const startMinuteNumAdmin = parseInt(startMinuteAdminStr, 10);
            const startMinuteNum = parseInt(startMinuteStr, 10);
            const endMinuteNum = parseInt(endMinuteStr, 10);

            for (
                let hour = startHourNumAdmin, minute = startMinuteNumAdmin;
                hour < endHourNum || (hour === endHourNum && minute <= endMinuteNum);
                minute += this.interval
            ) {
                if (minute >= 60) {
                    hour += Math.floor(minute / 60);
                    minute %= 60;
                }

                if (hour > startHourNum || (hour === startHourNum && minute >= startMinuteNum)) {
                    const hourStr = String(hour).padStart(2, '0');
                    const minuteStr = String(minute).padStart(2, '0');
                    const timeSlot = `${hourStr}:${minuteStr}`;

                    let slots = {
                        time: timeSlot,
                        date: dateNow,
                        schedulings: this.filterSchedulingsTime(filterSchedulings, timeSlot),
                        intervals: this.getIntervalsForTimeSlot(this.intervals, dayOfWeek, timeSlot)
                    };

                    timeSlots.push(slots);
                }
            }
        }
        return timeSlots;
    }


    getIntervalsForTimeSlot(intervalData, day, time) {
        const results = [];
        intervalData.forEach(person => {
            const intervals = this.parseIntervalData(person.intervals);
            if (intervals) {
                intervals.forEach(interval => {
                    if (interval.name === day) {
                        interval.intervals.forEach(slot => {
                            if (time >= slot.inicio && time < slot.fim) {
                                results.push({
                                    name: person.person.name,
                                    event: "Intervalo"
                                });
                            }
                        });
                    }
                });
            }
        });
        return results;
    }

    

    filterSchedulingsDate(targetDate) {
        function formatedObj(hour)
        {
            const hourObj = JSON.parse(hour);

            return hourObj.date
        }
        
        // Filtrar os agendamentos com base na data de destino
        const filteredScheduling = this.scheduling.filter((item) => this.isDateMatch(
            formatedObj(item.hour), 
            targetDate
         ));

        return filteredScheduling;
    }

    filterSchedulingsTime(scheduling, targetTime) {

        function formatedObj(hour)
        {
            const hourObj = JSON.parse(hour);

            return hourObj.time
        }
        // Filtrar os agendamentos com base no tempo de destino
        const filteredScheduling = scheduling.filter((item) => this.isTimeMatch(
            formatedObj(item.hour), 
            targetTime
        ));

        return filteredScheduling;
    }

    formatDate(date) {


        const daysOfWeekNames = {
            "SEG.": "Segunda-Feira",
            "TER.": "Terça-Feira",
            "QUA.": "Quarta-Feira",
            "QUI.": "Quinta-Feira",
            "SEX.": "Sexta-Feira",
            "SÁB.": "Sábado",
            "DOM.": "Domingo"
        };


        const dayOfWeekName = daysOfWeekNames[date.dayOfWeek];

        if (dayOfWeekName) {

            if(date.month < 10)
            {
                if(date.day < 10)
                {
                    return `${dayOfWeekName} 0${date.day}/0${date.month}/${date.year}`;
                }
                return `${dayOfWeekName} ${date.day}/0${date.month}/${date.year}`;
            }

            if(date.day < 10)
            {
                return `${dayOfWeekName} 0${date.day}/${date.month}/${date.year}`;
            }

            return `${dayOfWeekName} ${date.day}/${date.month}/${date.year}`;
            
        } else {
            return "Data inválida";
        }
    }

    isDateMatch(date1, date2) {
        // Remove espaços extras e converte para letras minúsculas para garantir uma comparação case-insensitive
        const formattedDate1 = date1.replace(/\s+/g, '').toLowerCase();
        const formattedDate2 = date2.replace(/\s+/g, '').toLowerCase();
        
        return formattedDate1 === formattedDate2;
    }
    

    isTimeMatch(time1, time2) {

        const formattedTime1 = time1.replace(/\s+/g, '').toLowerCase();
        const formattedTime2 = time2.replace(/\s+/g, '').toLowerCase();

        return formattedTime1 === formattedTime2;
    }

    parseIntervalData(intervalData) {
        try {
            return JSON.parse(intervalData);
        } catch (error) {
            console.error("Error parsing interval data:", error);
            return null;
        }
    }
    
}

export default GenerateTimeSlots;
