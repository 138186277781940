// src/echo.js
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

const echo = new Echo({
    broadcaster: 'pusher',
    key: "514a0c39537b78d1b063",
    cluster: "mt1",
    encrypted: true,
    forceTLS: true
});

export default echo;
