import axios from "axios";
import { API_URL } from "../../config";

//API

export async function postCompanyData(form)
{
    const response = await axios.post(`${API_URL}/company`, form);

    return response.data
}

export async function postOfficeHour(form)
{
    const response = await axios.post(`${API_URL}/officehour`, form);

    return response.data
}

export async function getOfficeHour(adminId)
{
    const response = await axios.get(`${API_URL}/officehour?adminId=${adminId}`);

    return response.data
}

export async function getServices(adminId)
{
    const response = await axios.get(`${API_URL}/services?adminId=${adminId}`);

    return response.data
}

export async function postService(form)
{
    const response = await axios.post(`${API_URL}/services`, form);

    return response.data
}

export async function findService(id)
{
    const response = await axios.get(`${API_URL}/services/${id}`);

    return response.data
}

export async function putService(form, id)
{
    const response = await axios.put(`${API_URL}/services/${id}`, form);

    return response.data.data
}

export async function deleteService(id)
{
    const response = await axios.delete(`${API_URL}/services/${id}`);

    return response.data.data
}

export async function getCollaborators(id)
{
    const response = await axios.get(`${API_URL}/colaborators?adminId=${id}`);

    return response.data;
}

export async function postCollaborator(form)
{
    const response = await axios.post(`${API_URL}/colaborators`, form);

    return response.data
}

export async function findCollaborator(id)
{
    //console.log(`${API_URL}/colaborators/${id}`);

    const response = await axios.get(`${API_URL}/colaborators/${id}`);

    //console.log(response.data);

    return response.data;
}

export async function putCollaborator(id, DATA)
{
    const response = await axios.put(`${API_URL}/colaborators/${id}`, DATA, {
        headers: {
            // Não defina o 'Content-Type' manualmente para 'multipart/form-data'
            // O Axios e o FormData cuidarão disso
            'Accept': 'application/json', // Isso pode ajudar dependendo da configuração do seu servidor
        },
    });
    return response.data;
}

export async function deleteCollaborator(id)
{
    const response = axios.delete(`${API_URL}/colaborators/${id}`);

    return (await response).data;
}

export async function getCompany(id)
{
    const response = axios.get(`${API_URL}/company?adminId=${id}`);

    return (await response).data;
}

export async function collaboratorUpdate(data)
{
    const response = await axios.post(`${API_URL}/collaborator-update`, data);

    return response.data
}

//HELPERS

export function verifyFormTime(formTime){
    for (const day of formTime) {
        // Verifica se start ou end estão preenchidos, mas não ambos
        if ((day.start && !day.end) || (!day.start && day.end)) {
            // Lança um erro ou retorna uma mensagem específica
            return true
        }
    }
}

export function validateForm(data, errors) {


    const fieldErrors = {};

    const checkField = (field, errorMessage) => {
        if (typeof data[field] === 'string' && data[field].trim() === '') {
            fieldErrors[field] = errorMessage;
        }
    };

    for (const field in errors) {
        checkField(field, errors[field]);
    }

    function validateWorkingHours(workingHours) {
        for (const day of workingHours) {
            if (day.start.trim() !== '' && day.end.trim() !== '') {
                return true;
            }
        }
        return false;
    }

    if (!validateWorkingHours(data.daysHours)) {
        fieldErrors.daysHours = "Você tem que informar ao menos um dia de expediente do estabelecimento";
    }

    return fieldErrors;
}







