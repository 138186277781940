import { useState } from "react";
import Validator from "../../../../lib/Validator";
import { postCompanyData } from "../../functions";
import { BsCaretRightFill, BsDatabaseFillCheck } from "react-icons/bs";
import { SpinnerCircular } from "spinners-react";

export default function Address({form, setForm, setPages})
{

    const [errors, setErrors] = useState({
        address:'',
        neighborhood:'',
        number:'',
        state:'',
        city:'',
    })

    const [sppiner, setSpinner] = useState(false);

    async function store()
    {

        setSpinner(true);

        const rules = {
            address:        'required',
            neighborhood:   'required',
            number:         'required',
            state:          'required',
            city:           'required'
        };

        const validator = new Validator(rules);

        validator.setCustomMessages({
            'address.required': 'O Rua/Av não pode ser vazio.',
            'neighborhood.required': 'O Bairro não pode ser vazio.',
            'number.required': 'O Número não pode ser vazio.',
            'state.required': 'O Estado não pode ser vazio.',
            'city.required': 'A Cidade não pode ser vazio.',
        });
        
        const validationErrors = validator.validate(form);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setSpinner(false);
            return
        } else {

            console.log("Formulário válido, enviar dados:", form);

            setErrors({
                address:'',
                neighborhood:'',
                number:'',
                state:'',
                city:'',
            });
        }


        const result = await postCompanyData(form);

        console.log(result);

        setSpinner("success");

    }


    return(
        <div className="address-form">

            <p>Rua/Av</p>
            <input value={form.address} onChange={(event)=>{setForm((form)=>({...form, address: event.target.value}))}} type="text" placeholder="Digite o nome de sua rua ou avenida"/>
            <p className="alert-errors">{errors.address}</p>

            <p>Bairro</p>
            <input value={form.neighborhood} onChange={(event)=>{setForm((form)=>({...form, neighborhood: event.target.value}))}} type="text" placeholder="Digite o nome de seu bairro"/>
            <p className="alert-errors">{errors.neighborhood}</p>

            <p>Número</p>
            <input value={form.number} onChange={(event)=>{setForm((form)=>({...form, number: event.target.value}))}} type="text" placeholder="Digite o número"/>
            <p className="alert-errors">{errors.number}</p>

            <p>Estado</p>
            <input value={form.state} onChange={(event)=>{setForm((form)=>({...form, state: event.target.value}))}} type="text" placeholder="Digite o estado"/>
            <p className="alert-errors">{errors.state}</p>

            <p>Cidade</p>
            <input value={form.city} onChange={(event)=>{setForm((form)=>({...form, city: event.target.value}))}} type="text" placeholder="Digite a cidade"/>
            <p className="alert-errors">{errors.city}</p>

            <button className="btn-center" onClick={()=>{store()}} >
                {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <> Registro salvo com sucesso! <BsDatabaseFillCheck size={20}/> </> :  "Salvar"}
            </button>

        </div>
    )
}