
export const MONTHS_AND_YAERS_STRING = [

    {id: 1, month: "janeiro", year: '2024'},
    {id: 2, month: "fevereiro", year: '2024'},
    {id: 3, month: "março", year: '2024'},
    {id: 4, month: "abril", year: '2024'},
    {id: 5, month: "maio", year: '2024'},
    {id: 6, month: "junho", year: '2024'},
    {id: 7, month: "julho", year: '2024'},
    {id: 8, month: "agosto", year: '2024'},
    {id: 9, month: "setembro", year: '2024'},
    {id: 10, month: "outubro", year: '2024'},
    {id: 11, month: "novembro", year: '2024'},
    {id: 12, month: "dezembro", year: '2024'},
] 