import { useState, useContext } from "react";
import { BsPersonCheck, BsCaretRightFill, BsBarChart , BsArrowClockwise, BsHouseDoor, BsQuestionCircle, BsExclamationCircle } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { FaRegComment, FaRegStar } from "react-icons/fa";

export default function SideBar({sideBarOpen, setSideBarOpen})
{
    const navigate = useNavigate();
    
    const {user, logout} = useContext(AuthContext);

    function handlePage(page)
    {
        setSideBarOpen(false);
        
        navigate(page);
    }

    return(

        <div className={sideBarOpen === false ? "side-bar-close" : "side-bar-open"}>

            <div className={sideBarOpen === false ? "side-bar-close-header" : "side-bar-open-header"}>
                <img src='./images/jadde_menu.png'/>
                <span>Escolha uma página..</span>
            </div>

            <ul>

                <li onClick={()=>{handlePage('/')}}>
                    <BsHouseDoor size={20} color="#fff"/>
                    <span>Inicio</span>
                    <BsCaretRightFill size={20} color="#fff"/>
                </li>

                {user?.type=== "admin" &&
                    <li onClick={()=>{handlePage('/settings')}}>
                        <CiSettings size={25} color="#fff"/>
                        <span>Configurações</span>
                        <BsCaretRightFill size={20} color="#fff"/>
                    </li>
                }

                {user?.type=== "admin" &&
                    <li onClick={()=>{handlePage('/client')}}>
                        <BsPersonCheck size={25} color="#fff"/>
                            <span>Clientes</span>
                        <BsCaretRightFill size={20} color="#fff"/>
                    </li>
                }



                <li onClick={()=>{handlePage('/recurrence')}}>
                    <BsArrowClockwise size={25} color="#fff"/>
                        <span>Recorrência</span>
                    <BsCaretRightFill size={20} color="#fff"/>
                </li>
       
                <li onClick={()=>{handlePage('/billing')}}>
                    <BsBarChart size={25} color="#fff"/>
                        <span>Faturamento</span>
                    <BsCaretRightFill size={20} color="#fff"/>
                </li>

                <li onClick={()=>{handlePage('/help-support')}}>
                    <BsQuestionCircle size={25} color="#fff"/>
                        <span>Ajuda Suporte</span>
                    <BsCaretRightFill size={20} color="#fff"/>
                </li>
                




            </ul>

            <div className="mt-50"></div>

        </div>
    )
}