import axios from "axios";
import { API_URL } from "../../config";

export async function getCollaborators(id)
{
    const response = axios.get(`${API_URL}/colaborators?adminId=${id}`);


    return (await response).data;
}
//RETORNA O DIA, MÊS, ANO ATUAL E NOME DA SEMANA

export function getDayMonthYearToday() {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1; // Adiciona 1 porque os meses são base 0 (0-11)
    const year = today.getFullYear();
    const dayOfWeek = today.toLocaleDateString('pt-BR', { weekday: 'short' }).toUpperCase();
    
    return { day, month, year, dayOfWeek };
}

export function getDayNowCalendar(dateString)
{
    const date = new Date(dateString);

    function getDayOfWeek(dayIndex) {
        const daysOfWeek = ["DOM.", "SEG.", "TER.", "QUA.", "QUI.", "SEX.", "SÁB."];
        return daysOfWeek[dayIndex];
    }

    const formattedDate = {
        "day": date.getDate(),
        "month": date.getMonth() + 1,
        "year": date.getFullYear(),
        "dayOfWeek": getDayOfWeek(date.getDay())
    };

    return formattedDate;
}

//GERAR DIAS DO MÊS INFORMADO

export function getMonthDates(year, month) {
    const firstDay = new Date(year, month - 1, 1); // Mês é base 0, então subtraímos 1
    const lastDay = new Date(year, month, 0);
  
    const daysArray = [];
    
    for (let date = new Date(firstDay); date <= lastDay; date.setDate(date.getDate() + 1)) {
      const dayOfWeek = date.toLocaleDateString('pt-BR', { weekday: 'short' }).toUpperCase();
      
      daysArray.push({
        name: dayOfWeek,
        day: date.getDate(),
        month: date.getMonth() + 1, // Adiciona 1 para ajustar o mês (0-11 para 1-12)
        year: date.getFullYear()
      });
    }
    
    return daysArray;
}

//GET HORÁRIOS DE AGENDAMENTO CONFORME O EXPEDIENTE

export async function getOfficeHourAndSchedulingsAdmin(user)
{
    try {
        const response = await axios.get(`${API_URL}/officehour?adminId=${user}`);

        return response.data

    } catch (error) {
        console.log(error)
    }
}

//GET HORÁRIOS DE AGENDAMENTO CONFORME O EXPEDIENTE

export async function getOfficeHourCollaborator(id)
{
    try {
        const response = await axios.get(`${API_URL}/officehour?collaboratorId=${id}`);

        console.log(`${API_URL}/officehour?collaboratorId=${id}`);

        return response.data

    } catch (error) {
        console.log(error)
    }
}

//GET HORÁRIOS DE AGENDAMENTO CONFORME O EXPEDIENTE E COLABORADOR SELECIONADO

export async function getOfficeHourAndSchedulingsCollaborator(userId, collaboratorId)
{
    try {

        const response = await axios.get(`${API_URL}/officehour?adminId=${userId}&collaboratorId=${collaboratorId}`);

        return response.data

    } catch (error) {
        console.log(error)
    }
}

//GET HORÁRIOS DE AGENDAMENTO CONFORME O EXPEDIENTE E COLABORADOR QUANDO ESTÁ LOGADO

export async function getOfficeHourAndSchedulingsCollaboratorLogin(personId)
{
    try {

        const response = await axios.get(`${API_URL}/officehour?personId=${personId}`);

        return response.data

    } catch (error) {
        console.log(error)
    }
}

//SEPARA AS 3 PRIMEIRAS LETRAS DA STRING PARA RENDERIZAR O NAME DA SEMANA NO CALENDARIO

export function getFirstThreeLetters(str) {
    return str.substring(0, 3);
}

export async function getBilligAdmin(id)
{
  const response = await axios.get(`${API_URL}/billing?adminId=${id}`);

  return response.data;
}

export async function getBilligCollaborator(id)
{
  const response = await axios.get(`${API_URL}/billing?collaboratorId=${id}`);

  console.log(`${API_URL}/billing?collaboratorId=${id}`);

  return response.data;
}

export async function schedulingCancel(id)
{
    const response = await axios.put(`${API_URL}/scheduling-cancel/${id}`);

    return response.data
}

export function genereteTimes(date, collaboratorDaysHours, schedulings, officeHour, intervals) {

    let day;
    
    if(date.dayOfWeek)
    {
        day = date.dayOfWeek.substring(0, 3);
    }
    else if(date?.name){
        day = date?.name.substring(0, 3);
    }
    else{
       
        day = date.substring(0, 3);
    }

    const interval = parseInt(officeHour.service_interval);
    const salonDaysHours = JSON.parse(officeHour.days_hours);

    const timeToMinutes = (time) => {
        if (!time) return null;
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const minutesToTime = (minutes) => {
        const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
        const mins = (minutes % 60).toString().padStart(2, '0');
        return `${hours}:${mins}`;
    };

    const salonDaySchedule = salonDaysHours.find(d => d.name === day);
    
    const employeeDaySchedule = collaboratorDaysHours.find(d => d.name === day);

    if (!salonDaySchedule || !employeeDaySchedule) {
        return 'Horário não disponível para este dia. Dia: ' + day;
    }

    const salonStart = timeToMinutes(salonDaySchedule.start);
    const employeeStart = timeToMinutes(employeeDaySchedule.start);
    const employeeEnd = timeToMinutes(employeeDaySchedule.end);

    if (employeeStart === null || employeeEnd === null) {
        return 'Horário do colaborador não definido para este dia.';
    }

    let firstSlot = salonStart;
    while (firstSlot < employeeStart) {
        firstSlot += interval;
    }

    if (firstSlot > employeeEnd) {
        return 'Nenhum slot disponível dentro do horário do colaborador.';
    }
    const intervalo = JSON.parse(officeHour?.days_hours)
    const dayIntervals = intervalo.find(d => d.name === day)?.officeHour || [];

    const isWithinInterval = (time) => {
        return dayIntervals.some(interval => {
            const start = timeToMinutes(interval.inicio);
            const end = timeToMinutes(interval.fim);
            return time >= start && time < end;
        });
    };

    let currentTime = firstSlot;
    let slots = [];
    let totalDurationServices = 0;

    let validadteNextHours = false;
    let currentTimeTemp = 0;
    let horario;
    let isBefore = false;
    let dataAgendamento;
    const dateString = `${date.year}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;

    while (currentTime + interval <= employeeEnd) {

        const currentSlotTime = minutesToTime(currentTime);

        const isSlotTaken = schedulings.some(scheduling => {
            
            const appointmentDate = new Date(scheduling.appointment_date);
            const appointmentDateString = `${appointmentDate.getFullYear()}-${String(appointmentDate.getMonth() + 1).padStart(2, '0')}-${String(appointmentDate.getDate()).padStart(2, '0')}`;
            const schedulingTime = JSON.parse(scheduling.hour).time;
            if(typeof scheduling.services != 'string' ){
                const durations = scheduling.services.map(service => parseInt(service.duration));
                totalDurationServices = durations.reduce((acc, curr) => acc + curr, 0);
            }

            dataAgendamento = appointmentDateString;
            if(appointmentDateString === dateString && schedulingTime === currentSlotTime){
                validadteNextHours = true;
                currentTimeTemp = (currentTime+totalDurationServices);
              
                return true;
            }
            horario = currentSlotTime;
           
            return false;
        });
        const diaSemana  = date?.dayOfWeek ?? date?.name;
        let nomeNormalizado;
        let isInterval = false;
        if(horario){
            const [hora, minuto] = horario.split(':').map(Number);
             isBefore = new Date() > new Date().setHours(hora, minuto, 0, 0);
        }
        if(typeof date == 'string'){
            nomeNormalizado = date;
        }
        else{
            nomeNormalizado = diaSemana.replace('.', '');
        }
        if(intervals){
            const dia = intervals.find(d => d.name === nomeNormalizado);
            isInterval = dia?.intervals.some(({ inicio, fim }) => {
               const inicioTime = timeToMinutes(inicio)
               const fimTime = timeToMinutes(fim)
                return currentTime >= inicioTime && currentTime <= fimTime;
            });
        }
        if((dataAgendamento === dateString && isBefore) || (isInterval) ){
         
            slots.push({ slot: minutesToTime(currentTime), available: false });
        }
        else if(validadteNextHours && currentTime <= currentTimeTemp){
            slots.push({ slot: minutesToTime(currentTime), available: false });
        }
        else{
            if (!isWithinInterval(currentTime) && !isSlotTaken) {
                slots.push({ slot: currentSlotTime, available: true });
            } else {
                slots.push({ slot: currentSlotTime, available: false });
            }
            validadteNextHours = false;
            currentTimeTemp = 0;
        }
        currentTime += interval;
     
    }

    return slots;
}



/*

export function genereteTimes(day, collaboratorDaysHours, schedulings, officeHour)
{

    const interval = parseInt(officeHour.service_interval);
    const salonDaysHours = JSON.parse(officeHour.days_hours);

    const timeToMinutes = (time) => {
        if (!time) return null;
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const minutesToTime = (minutes) => {
        const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
        const mins = (minutes % 60).toString().padStart(2, '0');
        return `${hours}:${mins}`;
    };

    const salonDaySchedule = salonDaysHours.find(d => d.name === day);

    const employeeDaySchedule = collaboratorDaysHours.find(d => d.name === day);



    if (!salonDaySchedule || !employeeDaySchedule) {
        return 'Horário não disponível para este dia. Dia: ' + day;
    }

    const salonStart = timeToMinutes(salonDaySchedule.start);
    const employeeStart = timeToMinutes(employeeDaySchedule.start);
    const employeeEnd = timeToMinutes(employeeDaySchedule.end);

    if (employeeStart === null || employeeEnd === null) {
        return 'Horário do colaborador não definido para este dia.';
    }

    let firstSlot = salonStart;
    while (firstSlot < employeeStart) {
        firstSlot += interval;
    }

    if (firstSlot > employeeEnd) {
        return 'Nenhum slot disponível dentro do horário do colaborador.';
    }



    let currentTime = firstSlot;
    let slots = [];

    while (currentTime + interval <= employeeEnd) {

        const currentSlotTime = minutesToTime(currentTime);

        const isSlotTaken = schedulings.some(scheduling => {

            const appointmentDate = new Date(scheduling.appointment_date);

            const appointmentDay = appointmentDate.getDay();

            const dayMapping = { 'DOM': 0, 'SEG': 1, 'TER': 2, 'QUA': 3, 'QUI': 4, 'SEX': 5, 'SÁB': 6 };

            const schedulingTime = JSON.parse(scheduling.hour).time;

            //console.log(`${dayMapping[day]} === ${appointmentDay} && ${schedulingTime} === ${currentSlotTime}`);

            return dayMapping[day] === appointmentDay && schedulingTime === currentSlotTime;
        });

        slots.push({ slot: currentSlotTime, available: !isSlotTaken });
        currentTime += interval;
    }


    return slots;
}
*/
export async function getClients(adminId)
{
    const response = await axios.get(`${API_URL}/client?adminId=${adminId}`);

    return response.data;
}

export async function getClientsByCollabortor(collaboratorId)
{
    const response = await axios.get(`${API_URL}/client?collaboratorId=${collaboratorId}`);


    return response.data;
}

export function minutosparaTimes (time) {
    if (!time) return null;
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};
export function transformStringDate(dateString) {
    // Validate if the input is a non-empty string
    if (typeof dateString !== 'string' || !dateString.trim()) {
        throw new Error('Input must be a non-empty string.');
    }

    // Create a date object from the string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        throw new Error('The provided date is invalid.');
    }

    // Days of the week in Portuguese
    const daysOfWeek = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];

    // Get the name of the day of the week
    const dayOfWeek = daysOfWeek[date.getUTCDay()];

    // Get the day, month, and year
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Remember that months start from zero
    const year = date.getUTCFullYear();

    // Format the date in the desired pattern
    const formattedDate = `${dayOfWeek} ${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

    return formattedDate;
}

export function formatObjInDate(dateObject) {
    // Mapping days of the week to the desired format
    const daysOfWeek = {
        'DOM.': 'Domingo',
        'SEG.': 'Segunda-Feira',
        'TER.': 'Terça-Feira',
        'QUA.': 'Quarta-Feira',
        'QUI.': 'Quinta-Feira',
        'SEX.': 'Sexta-Feira',
        'SÁB.': 'Sábado'
    };

    // Extracting values from the object
    const { day, month, year, dayOfWeek, name } = dateObject;

    // Determining the day of the week based on available keys
    const formattedDayOfWeek = dayOfWeek ? daysOfWeek[dayOfWeek] : daysOfWeek[name];

    // Formatting the date in the desired format
    const formattedDate = `${formattedDayOfWeek} ${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;

    return formattedDate;
}

export function formatDateTime(dateObject, timeString) {
    // Extrair valores do objeto
    const { day, month, year } = dateObject;
    
    // Adicionar zeros à esquerda se o dia ou mês for menor que 10
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Formatar a data no formato desejado
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

    // Concatenar a data formatada com o tempo fornecido
    const formattedDateTime = `${formattedDate} ${timeString}:00`;

    return formattedDateTime;
}

export async function storeScheduling(data)
{
    try {

        const response = await axios.post(`${API_URL}/scheduling`, data);

        return response.data

    } catch (error) {
        
        console.log(error);

        return
    }
}

export async function getVerifieldConfig(adminId)
{
    const response = await axios.get(`${API_URL}/verifieldConfig?adminId=${adminId}`);

    return response.data;
}

export async function getOfficeHourAdminAndCollaborator(collaboratorId)
{
    const response = await axios.get(`${API_URL}/OfficeHourAdminAndCollaborator?collaboratorId=${collaboratorId}`);

    return response.data;
}

export async function putScheduling(data, id){

    const response = await axios.put(`${API_URL}/scheduling/${id}`, data);

    return response.data;
}

export async function getBlock(id)
{
    const response = await axios.get(`${API_URL}/client-block?id=${id}`);

    return response.data;
}